import { getHumanDate, getHumanHour, getLocaleISOString } from '@/store/modules/Agenda/index'
import { dateEqual } from '@/store/helpers/survey/date'

const colorList = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#ff5722',
  '#795548',
  '#607d8b',
]

/**
 * @typedef {Object} AgendaCursorsState
 * @type {AgendaCursorsState}
 */
export const CursorState = {
  capsules: {
    list: [],
    i: 0,
  },
  medicines: [
    {
      value: "prout",
      text:"prout"
    }
  ],
}

export const CursorGetters = {
  capsulesList: state => state.capsules.list,
  medicines: state => state.medicines,
}

export const CursorActions = {
  /**
   * Create a new cursor for the medicine
   *
   * @param commit
   * @param {Date} today
   */
  addCapsule: ({ commit }, today) => {
    commit('ADD_CAPSULE', { color: colorList[Math.floor(Math.random() * Math.floor(12))], today })
  },
  deleteCursor: ({ commit, state }, capsuleId) => {
    commit('DELETE_CAPSULE', state.capsules.list.findIndex(capsule => capsule.id === capsuleId))
  },
  /**
   * Init the capsules with the values saved
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {Object[]} payload.capsules
   * @param {Date} payload.today
   */
  loadCapsules: ({ commit, rootState }, { capsules, today }) => {
    commit(
      'LOAD_CAPSULES',
      {
        capsules,
        medicines: rootState.User.user.studyData ? rootState.User.user.studyData.medicines.value : [],
        today,
      },
    )
  },
  loadMedicines: ({ commit, rootState }) => {
    commit(
      'LOAD_MEDICINES',
      rootState.User.user.studyData && rootState.User.user.studyData.medicines
      ? rootState.User.user.studyData.medicines.value
      : [],
    )
  },
  resetCursors: ({ commit }) => {
    commit('RESET_CURSORS')
  },
  /**
   * Select a medicine and save this medicine in the capsule object
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {String} payload.capsuleId
   * @param {String} payload.newMedicine
   */
  selectMedicine: ({ commit }, payload) => {
    commit('SELECT_MEDICINE', payload)
  },
  /**
   * Switch the value "isOther" in the capsule object to display the text field for the user
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {String} payload.capsuleId
   * @param {Boolean} payload.status
   */
  switchOtherStatus: ({ commit }, payload) => {
    commit('SWITCH_CAPSULE_STATUS', payload)
  },
  /**
   * Update the data for the capsule cursor.
   *
   * @param {ActionContext} context
   * @param {Object} newValues
   * @param {String} newValues.id
   * @param {Number} newValues.hour
   * @param {Number} newValues.day
   * @param {Date} newValues.today
   */
  updateCapsuleHourAndDate: ({ commit }, { id, hour, day, today }) => {
    const { year, month, date } = getHumanDate(day, today)

    commit(
      'UPDATE_CAPSULE_HOUR_DATE',
      {
        id,
        hour,
        day,
        humanHour: getHumanHour(hour.toFixed(2)
                                    .split('.')),
        humanDay: `${year}-${month}-${date}`,
      },
    )
  },
}

export const CursorMutations = {
  /**
   * Add the capsule in the state
   *
   * @param state
   * @param {Object} payload
   * @param {String} color
   * @param {Date} today
   * @constructor
   */
  ADD_CAPSULE: (state, { color, today }) => {
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    state.capsules.list.push({
      id: `capsule__${state.capsules.i++}`,
      d: -1,
      hd: getLocaleISOString(yesterday),
      h: 6,
      hh: '06:00',
      medicine: '',
      isOther: state.medicines.length === 1,
      color,
    })
  },
  DELETE_CAPSULE: (state, capsuleId) => {
    state.capsules.list.splice(capsuleId, 1)
  },
  LOAD_CAPSULES: (state, { capsules, medicines, today }) => {
    state.capsules.list.splice(0, state.capsules.list.length)

    capsules.forEach(({ name, datetime }) => {
      const [date, hour] = datetime.split(' ')
      const [h, m] = hour.split(':')
      const isOther = !medicines.includes(name)

      state.capsules.list.push({
        id: `capsule__${state.capsules.i++}`,
        d: dateEqual(new Date(date), today) ? 1 : -1,
        hd: date,
        h: parseFloat(`${h}.${m * 5 / 3}`),
        hh: hour,
        medicine: name,
        isOther,
        color: colorList[Math.floor(Math.random() * Math.floor(12))],
      })
    })
  },
  LOAD_MEDICINES: (state, medicines) => {
    state.medicines.splice(0, state.medicines.length)

    medicines.forEach(medicine => {
      state.medicines.push({
        text: medicine,
        value: medicine,
      })
    })

    state.medicines.push({
      text: 'Autre',
      value: 'other',
    })
  },
  RESET_CURSORS: state => {
    state.capsules.list.splice(0, state.capsules.list.length)
    state.capsules.i = 0
    state.medicines.splice(0, state.medicines.length)
  },
  SELECT_MEDICINE: (state, { capsuleId, newMedicine }) => {
    state.capsules.list.find(capsule => capsule.id === capsuleId).medicine = newMedicine
  },
  SWITCH_CAPSULE_STATUS: (state, { capsuleId, status }) => {
    state.capsules.list.find(capsule => capsule.id === capsuleId).isOther = status
  },
  UPDATE_CAPSULE_HOUR_DATE: (state, newValues) => {
    const capsuleUpdate = state.capsules.list.find(capsule => capsule.id === newValues.id)

    capsuleUpdate.h = newValues.hour
    capsuleUpdate.hh = newValues.humanHour
    capsuleUpdate.d = newValues.day
    capsuleUpdate.hd = newValues.humanDay
  },
}
