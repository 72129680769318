<template>
  <div id="parallax">
    <div ref="bg1" :style="{ backgroundSize: 'auto 100%' }" />
    <div ref="bg2" :style="{ backgroundSize: 'auto 100%' }" />
    <div ref="bg3" :style="{ backgroundSize: 'auto 100%' }" />
    <div ref="bg4" :style="{ backgroundSize: 'auto 100%' }" />
  </div>
</template>

<script>
export default {
  name: 'Parallax',

  created() {
    window.addEventListener('scroll', () => {
      const posLeft = this.calcPositionPercent(
        (window.innerHeight * this.calcPositionPercent()) / 100
      )
      if (
        this.$refs.bg1 &&
        this.$refs.bg2 &&
        this.$refs.bg3 &&
        this.$refs.bg4
      ) {
        this.$refs.bg1.style.backgroundPositionX = `-${((posLeft * 2100) /
          100) *
          0.8}px`
        this.$refs.bg2.style.backgroundPositionX = `-${((posLeft * 2100) /
          100) *
          0.5}px`
        this.$refs.bg3.style.backgroundPositionX = `-${((posLeft * 2100) /
          100) *
          0.3}px`
        this.$refs.bg4.style.backgroundPositionX = `-${((posLeft * 2100) /
          100) *
          0.1}px`
      }
    })
  },

  methods: {
    calcPositionPercent(position = 0) {
      return (
        ((window.scrollY + position) * 100) /
        document.querySelector('body').offsetHeight
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/style';

#parallax {
  background: $backgroundBody;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  > div {
    width: 100%;
    position: absolute;
    bottom: 0;

    &:nth-child(1) {
      height: 40vh;
      z-index: 4;
      background: transparent url('../../assets/img/bleu01.png') repeat-x bottom
        left 0;
    }

    &:nth-child(2) {
      height: 50vh;
      z-index: 3;
      background: transparent url('../../assets/img/bleu02.png') repeat-x bottom
        left;
    }

    &:nth-child(3) {
      height: 60vh;
      z-index: 2;
      background: transparent url('../../assets/img/bleu03.png') repeat-x bottom
        left;
    }

    &:nth-child(4) {
      height: 70vh;
      z-index: 1;
      background: transparent url('../../assets/img/bleu04.png') repeat-x bottom
        left 0;
    }
  }
}
</style>
