<template>
  <svg viewBox="0 0 280.1 50.5">
    <g>
      <path class="st0" d="M25.2,0c-7,0-12.9,2.5-17.8,7.4C2.5,12.3,0,18.3,0,25.2s2.5,12.9,7.4,17.8c4.9,4.9,10.9,7.4,17.8,7.4
		s12.9-2.5,17.9-7.4c4.9-4.9,7.4-10.9,7.4-17.8S48,12.3,43.1,7.4C38.2,2.5,32.2,0,25.2,0z M37.2,37.2c-3.3,3.3-7.3,5-12,5
		c-4.7,0-8.6-1.7-12-5c-3.3-3.3-5-7.3-5-12c0-4.7,1.7-8.7,5-12c3.3-3.3,7.3-5,12-5c4.7,0,8.7,1.7,12,5c3.3,3.3,5,7.3,5,12
		C42.2,29.9,40.5,33.9,37.2,37.2z"/>
      <path class="st0" d="M99.8,0c-1.1,0-2.1,0.4-3,1.2C96,2.1,95.6,3,95.6,4.2v30L70.3,1.7c-0.5-0.7-1.2-1.2-2.1-1.4
		C67.4,0,66.6,0,65.7,0.3c-0.8,0.3-1.5,0.8-2,1.5c-0.5,0.7-0.8,1.5-0.8,2.4v42.1c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2
		c1.1,0,2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9v-30l25.3,32.5v0h0c0.1,0.1,0.1,0.1,0.1,0.1s0,0,0.1,0.1v0l0,0
		c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.2,0.1
		c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.1,0c0.1,0,0.2,0,0.4,0.1
		c0.1,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0l0.1,0c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0,0.1,0c0.2-0.1,0.4-0.2,0.6-0.2h0
		c0.2-0.1,0.3-0.2,0.5-0.4l0,0c0.1-0.1,0.1-0.1,0,0c0,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1
		c0.1-0.1,0.1-0.1,0.1-0.1l0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.1-0.1c0-0.1,0.1-0.2,0.1-0.2l0.1-0.2l0.1-0.2c0,0,0-0.1,0.1-0.2
		c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0.1-0.2l0-0.2c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2V4.2
		c0-1.1-0.4-2.1-1.2-2.9C101.9,0.4,100.9,0,99.8,0z"/>
      <path class="st0" d="M123.3,0.1c-1.1,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,2.9v41.9c0,1.1,0.4,2.1,1.2,2.9
		c0.8,0.8,1.8,1.2,2.9,1.2c1.1,0,2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9V4.3c0-1.1-0.4-2.1-1.2-2.9C125.4,0.5,124.5,0.1,123.3,0.1
		z"/>
      <path class="st0" d="M174,23.1c1.9-2.5,2.9-5.3,2.9-8.4c0-4.1-1.5-7.5-4.6-10.4c-3-2.9-6.7-4.3-11.1-4.3h-14.6
		c-1.1,0-2.1,0.4-2.9,1.2C143,2,142.6,3,142.6,4.1v41.9c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2c1.1,0,2.1-0.4,2.9-1.2
		c0.8-0.8,1.2-1.8,1.2-2.9V29.3h7.1l9.3,18.9c0.8,1.5,2,2.3,3.7,2.3c0.6,0,1.2-0.1,1.8-0.4c1-0.5,1.7-1.3,2.1-2.4
		c0.4-1.1,0.3-2.1-0.2-3.1l-8-16.2C169.7,27.3,172.2,25.5,174,23.1z M166.6,19.1c-1.4,1.2-3.1,1.9-5.1,1.9h-10.7V8.3h10.4
		c2,0,3.8,0.6,5.2,1.9c1.5,1.2,2.2,2.8,2.2,4.5C168.6,16.4,168,17.8,166.6,19.1z"/>
      <path class="st0" d="M211,0c-7,0-12.9,2.5-17.8,7.4c-4.9,4.9-7.4,10.9-7.4,17.9s2.5,12.9,7.4,17.8c4.9,4.9,10.9,7.4,17.8,7.4
		s12.9-2.5,17.9-7.4c4.9-4.9,7.4-10.9,7.4-17.8s-2.5-12.9-7.4-17.9C223.9,2.5,217.9,0,211,0z M211,8.3c4.7,0,8.7,1.7,12,5
		c2.6,2.6,4.1,5.6,4.7,9h-33.4c0.6-3.4,2.1-6.4,4.7-9C202.3,9.9,206.3,8.3,211,8.3z M211,42.2c-4.7,0-8.6-1.7-12-5
		c-2.6-2.6-4.2-5.6-4.7-9h33.4c-0.6,3.4-2.1,6.4-4.7,9C219.6,40.5,215.6,42.2,211,42.2z"/>
      <path class="st0" d="M263.4,21.1h-0.2c-3.4,0-5.9-0.8-7.3-2.4c-0.9-1-1.3-2.1-1.3-3.3c0.1-2,0.9-3.7,2.5-5.1
		c1.6-1.4,3.5-2.1,5.7-2.1c2.2,0,4.1,0.4,5.7,1.2c1.9,0.9,3.1,2.1,3.5,3.7c0.3,1.1,0.9,1.9,1.9,2.5c1,0.6,2,0.7,3.1,0.4
		s1.9-0.9,2.5-1.9c0.6-1,0.7-2,0.5-3.1c-1-3.7-3.3-6.6-6.9-8.6c-3-1.6-6.4-2.5-10.3-2.5c-4.4,0-8.2,1.5-11.4,4.4
		c-3.2,2.9-4.8,6.5-5,10.7c-0.2,3.5,1,6.6,3.5,9.3c3,3.2,7.4,4.9,13.2,5c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0h0
		c3.4,0,5.9,0.8,7.3,2.4c0.9,1,1.3,2.1,1.3,3.3c-0.1,2-0.9,3.7-2.5,5.1c-1.6,1.4-3.5,2.1-5.7,2.1c-2.2,0-4.1-0.4-5.8-1.2
		c-1.9-0.9-3-2.1-3.4-3.7c-0.3-1.1-0.9-1.9-1.9-2.5c-1-0.6-2-0.7-3.1-0.4s-1.9,0.9-2.5,1.9c-0.6,1-0.7,2-0.4,3.1
		c1,3.8,3.3,6.6,6.9,8.6c3,1.6,6.4,2.5,10.3,2.5c4.4,0,8.2-1.5,11.4-4.4c3.2-2.9,4.8-6.5,5-10.7c0.2-3.6-1-6.7-3.5-9.3
		C273.5,22.9,269.1,21.2,263.4,21.1z"/>
      <circle class="st0" cx="25.5" cy="25.2" r="5"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

svg {
  .st0 {
    fill: $logoColor;
  }
}
</style>
