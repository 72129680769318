import {
  CursorActions,
  CursorGetters,
  CursorMutations,
  CursorState,
} from '@/store/modules/Agenda/Cursors'
import {
  SliderActions,
  SliderGetters,
  SliderMutations,
  SliderState,
} from '@/store/modules/Agenda/Sliders'

/**
 * @param {Date} date
 */
export const getLocaleISOString = date => {
  const year = date.getFullYear()
  let month = date.getMonth()
  let day = date.getDate()

  month <= 8 ? month = `0${month + 1}` : month += 1
  day < 10 ? day = `0${day}` : day

  return `${year}-${month}-${day}`
}

/**
 * Converts the hour to 00:00 format.
 *
 * @param {string[]} hourData
 */
export const getHumanHour = ([hour, minutes]) => {
  if (hour <= 9) {
    hour = `0${hour}`
  } else if (parseInt(hour) === 24) {
    hour = '00'
  }

  if (minutes === undefined) {
    minutes = '00'
  } else {
    const min = Math.round(minutes * 60 / 100)
    minutes = min <= 9 ? `0${min}` : `${min}`
  }

  return `${hour}:${minutes}`
}

/**
 * @param {Number} day
 * @param {Date|null} today[null]
 * @return {{date: string, month: string, year: number}}
 */
export const getHumanDate = (day, today = null) => {
  if (today) {
    if (day === -1) {
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      today = yesterday
    }
  } else {
    today = day === -1 ? Date.yesterday() : new Date()
  }
  const year = today.getFullYear()
  const month = today.toLocaleDateString(
    navigator.language || 'fr-FR',
    { month: '2-digit' },
  )
  const date = today.getDate()
  const getDateTwoChar = dateOneChar => {
    return dateOneChar <= 9 ? `0${dateOneChar}` : `${dateOneChar}`
  }

  return { year, month, date: getDateTwoChar(date) }
}

const getSleepValue = ({ start, end, sleeps }) => {
  let sleepValue = []
  if (sleeps.length === 1) {
    sleepValue.push({
      from_datetime: `${start.hd} ${start.hh}`,
      asleep: `${sleeps[0].start.hd} ${sleeps[0].start.hh}`,
      to_datetime: `${sleeps[0].end.hd} ${sleeps[0].end.hh}`,
      wakeup: `${end.hd} ${end.hh}`,
    })
  } else {
    sleepValue.push({
      from_datetime: `${start.hd} ${start.hh}`,
      asleep: `${sleeps[0].start.hd} ${sleeps[0].start.hh}`,
      to_datetime: `${sleeps[0].end.hd} ${sleeps[0].end.hh}`,
    })
    sleeps.forEach((sleep, index) => {
      if (index !== 0 && index + 1 <= sleeps.length - 1) {
        sleepValue.push({
          from_datetime: `${sleep.start.hd} ${sleep.start.hh}`,
          to_datetime: `${sleep.end.hd} ${sleep.end.hh}`,
        })
      }
    })
    sleepValue.push({
      from_datetime: `${sleeps[sleeps.length - 1].start.hd} ${sleeps[sleeps.length - 1].start.hh}`,
      to_datetime: `${sleeps[sleeps.length - 1].end.hd} ${sleeps[sleeps.length - 1].end.hh}`,
      wakeup: `${end.hd} ${end.hh}`,
    })
  }

  return sleepValue
}

/**
 * @typedef {Object} AgendaState
 * @type {AgendaState}
 */
const state = {
  ...CursorState,
  ...SliderState,
  graphValues: {},
}

const getters = {
  ...CursorGetters,
  ...SliderGetters,
  graphValues: state => state.graphValues,
}

const actions = {
  ...CursorActions,
  ...SliderActions,
  buildGraphValues: ({ commit }) => {
    const sleep = SliderState.sliders.list.filter(slider => slider.type === 'sleep')[0]
    commit(
      'BUILD_GRAPH_VALUES',
      {
        meds: [
          ...CursorState.capsules.list.map(capsule => {
            return {
              name: capsule.medicine,
              datetime: `${capsule.hd} ${capsule.hh}`,
            }
          }),
        ],
        sleep: sleep ? getSleepValue(sleep) : [],
        lumino: [
          ...SliderState.sliders.list.filter(slider => slider.type === 'lumino')
                        .map(slider => {
                          return {
                            from_datetime: `${slider.start.hd} ${slider.start.hh}`,
                            to_datetime: `${slider.end.hd} ${slider.end.hh}`,
                          }
                        }),
        ],
        nap: [
          ...SliderState.sliders.list.filter(slider => slider.type === 'nap')
                        .map(slider => {
                          return {
                            from_datetime: `${slider.start.hd} ${slider.start.hh}`,
                            to_datetime: `${slider.end.hd} ${slider.end.hh}`,
                          }
                        }),
        ],
      },
    )
  },
  resetAgenda: ({ dispatch }) => {
    dispatch('resetCursors')
    dispatch('resetSliders')
  },
}

const mutations = {
  ...CursorMutations,
  ...SliderMutations,
  BUILD_GRAPH_VALUES: (state, values) => {
    state.graphValues = values
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
