<template>
  <v-menu offset-y bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark text v-bind="attrs" v-on="on"
        ><v-icon color="white">mdi-flag</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-model="$root.$i18n.locale"
        v-for="language in languages"
        :key="language.code"
        @click="changeLanguage(language.code)"
      >
        <v-list-item-avatar tile size="24">
          <v-img :src="language.flagSrc" />
        </v-list-item-avatar>
        <v-list-item-title
          ><p>{{ language.title }}</p>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    languages: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions(['updateUser']),
    changeLanguage(code) {
      // global app
      this.$root.$i18n.locale = code
      // vuetify
      this.$root.$vuetify.lang.current = code
      this.$vuetify.lang.current = code
      // update user language setting if connected
      if (this.userIsConnected) {
        this.$set(this.userConnected.settings, 'language', code)
        this.updateUser({
          user: this.userConnected,
          data: { 'settings': this.userConnected.settings },
        })
      }
    },
  },
  computed: {
    ...mapGetters(['userConnected', 'userIsConnected']),
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/style';

button {
  min-width: 32px;
  width: 32px;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.theme--light.v-list {
  background-color: $navbarBorderColor;

  .v-list-item {
    &:hover {
      background-color: $primaryColorDark;
    }

    a,
    p {
      cursor: pointer;
      height: 100%;
      margin: 0;
      align-items: center;
      color: white;
      font-size: 20px;
      text-decoration: none;
    }
  }
}
</style>
