<template>
  <v-app id="app">
    <navbar
        ref="navbar"
        :navigation-drawer-display.sync="navigationDrawer.display"
    />

    <main class="container" :class="{ 'extended-container': isExtendedContainer }">
      <router-view ref="body"/>
    </main>

    <navigation-drawer
        :display.sync="navigationDrawer.display"
        v-if="$vuetify.breakpoint.mobile"
    />

    <snackbar/>

    <parallax v-if="!$vuetify.breakpoint.mobile"/>

    <go-top-button/>
  </v-app>
</template>

<script>
import Navbar from '@/components/Template/Navbar'
import Parallax from '@/components/Template/Parallax'
import Snackbar from '@/components/Template/Snackbar'
import NavigationDrawer from '@/components/Template/NavigationDrawer'
import { mapActions } from 'vuex'
import GoTopButton from '@/components/Template/GoTopButton'

export default {
  name: 'App',

  components: {
    GoTopButton,
    NavigationDrawer,
    Snackbar,
    Parallax,
    Navbar,
  },

  data: () => ({
    appTitle: 'title.Oniros',
    navigationDrawer: {
      display: false,
    },
  }),

  computed: {
    pageTitle: function () {
      return this.$route.meta.title || this.appTitle
    },
    isExtendedContainer () {
      return this.$route.name === 'SummaryPatient'
    },
  },

  watch: {
    pageTitle: 'setPageTitle',
  },

  created () {
    this.setPageTitle()
    this.initJWTBody()
  },

  methods: {
    ...mapActions(['initJWTBody']),
    setPageTitle () {
      document.title = `${this.$t(this.pageTitle)}`
    },
  },
}
</script>

<style lang="scss">
@import "assets/style/style";

body {
  margin-bottom: 56px;
  font-size: 16px;
  background-color: $backgroundBody;

  @media all and (min-width: $s) {
    margin-bottom: 0;
  }

  * {
    font-family: "Unistra A", sans-serif;
    color: $primaryColorText;
  }

  .v-tooltip__content {
    span {
      color: white;
    }
  }

  .theme--light.v-application {
    background-color: transparent;

    h1 {
      font-size: 30px;
      font-weight: normal;
      margin-bottom: 20px;
      text-align: center;
      color: $primaryColorDark;

      @media all and (min-width: $s) {
        font-size: 50px;
      }
    }

    .v-btn, label, input, .information__snackbar.v-snack__content, .v-tooltip__content {
      font-size: 18px;
    }

    .v-tooltip__content {
      font-weight: bold;
    }

    .v-input--is-readonly {
      cursor: default;

      .v-input__slot {
        cursor: default;

        input {
          cursor: default;
        }
      }
    }
  }

  .v-dialog {
    > .v-card {
      > .v-card__title {
        background-color: $primaryColorDark;
        color: white;
        display: flex;
        align-items: center;
        font-size: 23px;

        .theme--light.v-icon {
          color: inherit;
        }
      }

      .v-card__text {
        margin-top: 20px;
      }
    }
  }

  .v-data-table-header th {
    white-space: nowrap;
    vertical-align: top;
  }

  .v-messages, .dialog-create-update.error--text, .dialog-create-update .error--text {
    font-size: 17px !important;
    font-weight: 650 !important;
  }

  .v-card__title {
    word-break: break-word;
  }
}
</style>

<style lang="scss" scoped>
@import "assets/style/style";

.container {
  @include container;
  margin: 93px auto 32px auto;
  z-index: 10;

  &.extended-container {
    width: 95%;
    max-width: 100%;
  }
}
</style>
