<template>
  <v-btn
    color="secondary"
    v-scroll="onScroll"
    v-show="!onTop"
    fab
    dark
    fixed
    bottom
    right
    small
    @click="toTop"
    depressed
    class="d-print-none"
  >
    <v-icon dark>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'GoTopButton',

  data() {
    return {
      onTop: false,
    }
  },

  mounted() {
    this.onTop = window.pageYOffset < 20
    this.onScroll()
  },

  methods: {
    onScroll(e = null) {
      if (typeof window === 'undefined' || e === null) return
      this.onTop = (window.pageYOffset || e.target.scrollTop || 0) < 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  z-index: 500 !important;
}
</style>
