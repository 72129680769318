import Vue from 'vue'
import '@/plugins/axios'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import UIcon from '@/plugins/unistra/UIcon'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import vuetify from '@/plugins/vuetify'
import capitalize from '@/mixins/capitalize'
import date, { datePrototypes } from '@/mixins/date'
import { humanDate } from '@/filters/date'

import VueMatomo from 'vue-matomo'

datePrototypes()
Vue.config.productionTip = false
Vue.component('u-icon', UIcon)

Vue.filter('humanDate', humanDate)

Vue.mixin(capitalize)
Vue.mixin(date)

if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_MATOMO_SERVER && process.env.VUE_APP_MATOMO_SITE_ID) {
    Vue.use(VueMatomo, {
      router,
      host: process.env.VUE_APP_MATOMO_SERVER,
      siteId: process.env.VUE_APP_MATOMO_SITE_ID,
      trackerFileName: 'm',
      debug: process.env.VUE_APP_MATOMO_DEBUG || false,
    })
  }

  Sentry.init({
    dsn: 'https://2d03cc81860f48cb90d133e319088fea@sentry.app.unistra.fr/29',
    environment: process.env.VUE_APP_DEPLOYEMENT_TAG_SENTRY,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  })
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
