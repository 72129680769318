import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      menus: {
        account: [
          {
            id: 'acc1',
            routeName: 'AdminSettings',
            text: 'navbar.account.settings',
            params: {},
          },
          {
            id: 'acc2',
            routeName: null,
            text: 'navbar.account.logoutAction',
            action: 'logout',
            params: {},
          },
        ],
        accountNotConnected: [
          {
            id: 'anc1',
            routeName: 'Login',
            text: 'general.buttons.login',
            params: {},
          },
        ],
        calendar: [
          {
            id: 'cal1',
            routeName: 'DayCalendar',
            text: 'navbar.calendar.dayCalendar',
            params: {},
          },
          {
            id: 'cal2',
            routeName: 'SurveyDayList',
            text: 'navbar.calendar.dayForm',
            params: {
              userId: this.userConnected ? this.userConnected.id : null,
              surveyCat: 'SURVEY',
            },
          },
        ],
        info: [
          {
            id: 'inf1',
            routeName: 'Project',
            text: 'navbar.infos.project',
            params: {},
          },
          {
            id: 'inf2',
            routeName: 'Legals',
            text: 'navbar.infos.legals',
            params: {},
          },
          {
            id: 'inf3',
            routeName: 'Presentation',
            text: 'navbar.infos.presentation',
            params: {},
          },
        ],
        infoNotConnected: [
          {
            id: 'inf1',
            routeName: 'Project',
            text: 'navbar.infos.project',
            params: {},
          },
          {
            id: 'inf2',
            routeName: 'Legals',
            text: 'navbar.infos.legals',
            params: {},
          },
        ],
      },
      selects: {
        groups: [],
        studies: [],
      },
    }
  },

  computed: {
    ...mapGetters([
      'userConnected',
      'userIsConnected',
    ]),
  },

  watch: {
    userIsConnected: function () {
      this.onLoadUserConnected()
    },
  },

  methods: {
    ...mapActions([
      'checkConnectionStatus',
      'loadUserConnected',
      'logout',
      'selectGroup',
      'selectStudy',
    ]),
    onLoadUserConnected () {
      this.loadUserConnected()
          .then(() => {
            this.selects.groups.splice(0, this.selects.groups.length)
            this.userConnected.groups.forEach(groupUser => {
              this.selects.groups.push({
                text: groupUser.humanName,
                value: groupUser.id,
              })
            })
            this.userConnected.studies.forEach(studyUser => {
              this.selects.studies.push({
                text: studyUser.label,
                value: studyUser.id,
              })
            })
            if (this.userConnected.settings && 'language' in this.userConnected.settings) {
              this.$root.$i18n.locale = this.userConnected.settings.language
              this.$vuetify.lang.current = this.userConnected.settings.language
            }
          })
    },
  },
}
