import { _axios } from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import store from '@/store'
import { JWTExpired, JWTTranslation } from '@/store/modules/Security'
import { myStatus } from '@/store/modules/User'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/project',
    name: 'Project',
    component: () => import('@/views/Static/Project'),
    meta: {
      title: 'title.project',
    },
  },
  {
    path: '/legals',
    name: 'Legals',
    component: () => import('@/views/Static/Legals'),
    meta: {
      title: 'title.legals',
    },
  },
  {
    path: '/presentation',
    name: 'Presentation',
    component: () => import('@/views/Static/Presentation'),
    meta: {
      requireAuth: true,
      title: 'title.presentation',
    },
  },
  {
    path: '/recovery-password',
    name: 'RecoveryPassword',
    component: () => import('@/views/Security/RecoveryPassword'),
  },
  {
    path: '/recovery-login',
    name: 'RecoveryLogin',
    component: () => import('@/views/Security/RecoveryLogin'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/Security/ResetPassword'),
  },
  {
    path: '/settings',
    name: 'AdminSettings',
    component: () => import('@/views/Admin/Settings'),
    meta: {
      requireAuth: true,
      title: 'title.settings.title',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Admin/Dashboard'),
    meta: {
      requireAuth: true,
      title: 'title.dashboard',
    },
  },
  {
    path: '/dashboard/patient/:patientId',
    name: 'DashboardPatient',
    component: () => import('@/views/Doctor/PatientDashboard'),
    meta: {
      requireAuth: true,
      statusRequired: ['MED', 'TEC', 'ADM', 'CHR'],
      title: 'dashboard.doctor.pageTitle',
    },
    props: true,
  },
  {
    path: '/calendar/:patientId',
    name: 'GeneralCalendar',
    component: () => import('@/views/Patient/GeneralCalendar'),
    meta: {
      requireAuth: true,
      title: 'title.calendar',
    },
    props: true,
  },
  {
    path: '/milestones/pdf/:patientId/:patientName',
    name: 'GeneralCalendarPdf',
    beforeEnter (to) {
      return _axios.get('users/' + to.params.patientId + '/milestones.pdf', { responseType: 'blob' })
                   .then((response) => {
                     const url = window.URL.createObjectURL(new Blob([response.data]))
                     const link = document.createElement('a')
                     link.href = url
                     link.setAttribute('download', i18n.t('title.calendar') + '_' + to.params.patientName + '.pdf')
                     document.body.appendChild(link)
                     link.click()
                   })

    },
    meta: {
      requireAuth: true,
    },
    props: true,
  },
  {
    path: '/results/csv/:patientId/:patientName/:full',
    name: 'PatientCsvResults',
    beforeEnter (to) {
      return _axios.get('users/' + to.params.patientId + '/results.csv?full=' + to.params.full, { responseType: 'blob' })
                   .then((response) => {
                     const url = window.URL.createObjectURL(new Blob([response.data]))
                     const link = document.createElement('a')
                     link.href = url
                     link.setAttribute('download', i18n.t('title.results') + '_' + to.params.patientName + '.csv')
                     document.body.appendChild(link)
                     link.click()
                   })

    },
    meta: {
      requireAuth: true,
    },
    props: true,
  },
  {
    path: '/survey/:surveyId/document/:documentId',
    name: 'surveyDocument',
    beforeEnter (to) {
      return _axios.get('surveys/' + to.params.surveyId + '/document/' + to.params.documentId, { responseType: 'blob' })
                   .then((response) => {
                     const url = window.URL.createObjectURL(new Blob([response.data]))
                     const link = document.createElement('a')
                     link.href = url
                     link.setAttribute('download', to.params.name)
                     document.body.appendChild(link)
                     link.click()
                   })

    },
    meta: {
      requireAuth: true,
    },
    props: true,
  },
  {
    path: '/day-calendar/:day?',
    name: 'DayCalendar',
    component: () => import('@/views/Patient/DayCalendar'),
    meta: {
      requireAuth: true,
      title: 'title.day.calendar',
    },
    props: true,
  },
  {
    path: '/admin/create-user',
    name: 'AdminCreateForm',
    component: () => import('@/views/Admin/Admin/Forms'),
    meta: {
      requireAuth: true,
      statusRequired: ['ADM'],
    },
  },
  {
    path: '/admin/users',
    name: 'AdminUserList',
    component: () => import('@/components/Admin/UserList/UserList'),
    props: true,
    meta: {
      requireAuth: true,
      statusRequired: ['ADM', 'TEC', 'MED', 'CHR'],
      title: 'title.admin.users',
    },
  },
  {
    path: '/forms/:patientId/:surveyCat',
    name: 'PatientForms',
    component: () => import('@/views/Doctor/PatientForms'),
    props: true,
    meta: {
      requireAuth: true,
      statusRequired: ['TEC', 'MED', 'ADM'],
      title: 'dashboard.doctor.patientStart',
    },
  },
  {
    path: '/form/:surveyId/:userId',
    name: 'Form',
    component: () => import('@/views/Patient/Survey'),
    props: true,
    meta: {
      statusRequired: ['TEC', 'MED', 'ADM'],
      requireAuth: true,
    },
  },
  {
    path: '/surveys/today/:userId/:surveyCat',
    name: 'SurveyDayList',
    component: () => import('@/views/Patient/SurveyDayList'),
    props: true,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/survey/:surveyId/:userId/:day',
    name: 'Survey',
    component: () => import('@/views/Patient/Survey'),
    props: true,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/summary/patient/:patientId',
    name: 'SummaryPatient',
    component: () => import('@/views/Doctor/PatientSummary'),
    meta: {
      requireAuth: true,
      statusRequired: ['MED', 'TEC', 'ADM', 'CHR'],
      title: 'summary.pageTitle',
    },
    props: true,
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Security/Login'),
    beforeEnter (to, from, next) {
      let jwtAccess = localStorage.getItem('JWT_ACCESS')
      let jwtRefresh = localStorage.getItem('JWT_REFRESH')

      if (jwtAccess && jwtRefresh) {
        const now = Math.trunc((new Date()).getTime() / 1000)
        jwtAccess = JWTTranslation(jwtAccess)
        jwtRefresh = JWTTranslation(jwtRefresh)

        if (jwtRefresh.body.exp > now && jwtAccess.body.exp > now) {
          next({ name: 'Dashboard' })
        }
      }

      next()
    },
  },
  {
    path: '*',
    name: 'Error404',
    component: () => import('@/views/Static/Error404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes,
})

router.beforeEach((to, from, next) => {
  store.dispatch('checkConnectionStatus')
  let jwtAccess = localStorage.getItem('JWT_ACCESS')
  let jwtRefresh = localStorage.getItem('JWT_REFRESH')

  if (to.meta.requireAuth) {
    if (jwtAccess && jwtRefresh && !JWTExpired(jwtRefresh)) {
      const statusRequired = to.meta.statusRequired

      const body = JWTTranslation(jwtAccess).body
      if (body.hasExpiredPassword || body.hasTempPassword) {
        next({ name: 'ResetPassword' })
      }

      if (statusRequired !== undefined) {
        if (!myStatus(statusRequired)) {
          store.dispatch(
            'displaySnackbar',
            {
              status: true,
              type: 'error',
              text: i18n.t('security.login.alert.unauthorized'),
            },
          )
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      store.dispatch('logout')

      store.dispatch(
        'displaySnackbar',
        {
          status: true,
          type: 'error',
          text: i18n.t('security.login.alert.error'),
        },
      )
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
