import { _axios } from '@/plugins/axios'
import Converter from '@/store/Converter'

/**
 * @typedef {Object} MilestoneJS
 * @property {Date} alertDate
 * @property {Date} date
 * @property {Date} maxDate
 * @property {Number} milestoneSettingsId - Id of the milestone linked
 * @property {Number} id - Id of the link between the user and the milestone
 * @property {Date} minDate
 * @property {Number} userId
 */
/**
 * @typedef {Object} MilestoneSettingsJS
 * @property {Number} day
 * @property {Number} id
 * @property {String} label
 * @property {Number} studyId
 * @property {Boolean} withAppointment
 */
/**
 * @type {MilestoneSettingsJS}
 */
export const milestoneSettingEmpty = {
  day: null,
  id: null,
  label: '',
  studyId: null,
  withAppointment: false,
}

/**
 * @typedef {Object} CalendarState
 * @property {MilestoneJS[]} calendar
 * @property {MilestoneSettingsJS[]} milestones
 * @type {CalendarState}
 */
const state = {
  calendar: [],
  milestoneSettings: [],
}

const getters = {
  /**
   * @param {CalendarState} state
   * @returns {MilestoneJS[]}
   */
  calendar: state => state.calendar,
  /**
   * @param {CalendarState} state
   * @returns {MilestoneSettingsJS[]}
   */
  milestoneSettings: state => state.milestoneSettings,
}

const actions = {
  /**
   * Requests the calendar creation
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {Number} payload.patientId
   * @param {String} payload.initDate
   * @returns {AxiosPromise<any>}
   */
  createCalendar: ({ commit }, { patientId, initDate }) => {
    return _axios.post(`users/${patientId}/usermilestones`, { date: initDate })
                 .then(response => {
                   commit('LOAD_USER_CALENDAR', response.data)
                 })
  },
  /**
   * Load the milestones settings
   *
   * @param {ActionContext} context
   * @param {Number} studyId
   * @returns {Promise<void>}
   */
  loadMilestones: ({ commit, state }, studyId) => {
    if (state.milestoneSettings.length === 0) {
      return _axios.get(`milestones?study_id=${studyId}`)
                   .then(response => {
                     commit('LOAD_MILESTONE_SETTINGS', response.data)
                   })
    }
  },
  /**
   * Load the general calendar of the user
   *
   * @param {ActionContext} context
   * @param {Number} patientId
   * @returns {AxiosPromise<any>}
   */
  loadUserCalendar: ({ commit }, patientId) => {
    return _axios.get(`users/${patientId}/usermilestones`)
                 .then(async response => {
                   commit('LOAD_USER_CALENDAR', response.data)
                 })
  },
  updateMilestone: (context, { id, date }) => {
    return _axios.patch(`usermilestones/${id}`, { date: date.toISOString() })
  },
}

const mutations = {
  /**
   * @param {CalendarState} state
   * @param {MilestoneSettingsJS[]} milestones
   * @constructor
   */
  LOAD_MILESTONE_SETTINGS: (state, milestones) => {
    state.milestoneSettings.splice(0, state.milestoneSettings.length)

    milestones.forEach(milestone => {
      state.milestoneSettings.push(Converter.ptj.milestoneSettings(milestone))
    })
  },
  /**
   *
   * @param {CalendarState} state
   * @param {MilestoneJS[]} calendar
   * @constructor
   */
  LOAD_USER_CALENDAR: (state, calendar) => {
    state.calendar.splice(0, state.calendar.length)

    calendar.forEach(milestone => {
      state.calendar.push(Converter.ptj.milestone(milestone))
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
