import { getHumanDate, getHumanHour, getLocaleISOString } from '@/store/modules/Agenda/index'
import { dateEqual } from '@/store/helpers/survey/date'

export const getSliderColor = sliderType => ({
  lumino: '#ffff00',
  nap: '#00ffff',
  sleep: '#0000ff',
}[sliderType])

export const stepCalculate = (hour, date, startH, startD, hourStep, maxStep) => {
  const step = Math.round(((hour - startH + (date === startD ? 0 : 24)) * 60) / hourStep)
  if (step < 0) {
    return 0
  } else if (step > maxStep) {
    return maxStep
  }

  return step
}

/**
 * Calculate the date, the humanDate, the hour and the humanHour for the slider or the sub-awakening
 * Get hour and minute of the slider start.
 * Add the difference to calculate the item hour and minutes.
 * Adjust if hour > 24 and write the good format for the hour and the minutes
 *
 * @param {Object} item - Item update (start, end, awakening, sleep, awakening-start, awakening-end)
 * @param {Number|String} hours - Hour difference with the hour start
 * @param {Number|String} minutes - Minutes differences with the minutes start
 * @param {String} type - the type name (start, end, awakening, sleep, awakening-start, awakening-end)
 * @param {Object} slider - the slider to get the start hour and the start minute
 * @param {Date} today[new Date()] - the slider to get the start hour and the start minute
 */
const calculateHourAndDate = (item, hours, minutes, type, slider, today = new Date()) => {
  // the hour/minutes start
  const [hourStart, minuteStart] = slider.start.hh.split(':')

  // add the difference
  hours = parseInt(hourStart) + hours
  minutes = parseInt(minuteStart) + minutes

  if (minutes >= 60) {
    minutes -= 60
    hours++
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }

  if (hours === 24) {
    item.h = parseFloat(`${minutes / 60}`)
    hours = '00'
    item.d = 1
    item.hd = getLocaleISOString(today)
  } else if (hours > 24) {
    item.h = parseFloat(`${hours - 24 + (minutes / 60)}`)
    hours -= 24
    item.d = 1
    item.hd = getLocaleISOString(today)
  } else {
    item.h = parseFloat(`${hours + (minutes / 60)}`)
    item.d = slider.start.d
    item.hd = slider.start.hd
  }

  if (hours > 0 && hours <= 9) {
    hours = `0${hours}`
  }

  item.hh = `${hours}:${minutes}`
}

/**
 * @typedef {Object} CalendarSliderCursor
 * @property {Number} d - Relative date (1 = today, -1 = yesterday)
 * @property {Number} h - The hour in base 10
 * @property {String} hd - "Human Date" is the date with the format "YYYY-MM-DD"
 * @property {String} hh - "Human Hour" is the hour with the format "HH:MM"
 */
/**
 * @typedef {Object} CalendarSliderSleep
 * @property {String} id
 * @property {CalendarSliderCursor} start
 * @property {CalendarSliderCursor} end
 */
/**
 * @typedef {Object} CalendarSlider
 * @property {String} id
 * @property {String} dashArray
 * @property {String} dashOffset
 * @property {Boolean} isSimple
 * @property {String} type
 * @property {Number} i
 * @property {CalendarSliderCursor} start
 * @property {CalendarSliderCursor} end
 * @property {CalendarSliderCursor} awakening
 * @property {CalendarSliderCursor} sleep
 * @property {CalendarSliderSleep[]} sleeps
 */

/**
 * @typedef {Object} SliderState
 * @property {Object} sliders
 * @property {CalendarSlider[]} sliders.list
 * @property {Number} sliders.i
 */
/**
 * @type {SliderState}
 */
export const SliderState = {
  sliders: {
    list: [],
    i: 0,
  },
}

export const SliderGetters = {
  hasSleepSlider: state => state.sliders.list.find(slider => slider.type === 'sleep') !== undefined,
  slidersList: state => state.sliders.list,
}

export const SliderActions = {
  /**
   * Add a new slider
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {String} payload.type
   * @param {Date} payload.today
   */
  addSlider: ({ commit }, payload) => {
    commit('ADD_SLIDER', payload)
  },
  addSleep: ({ commit }, sliderId) => {
    commit('ADD_SLEEP', sliderId)
  },
  /**
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {string} payload.sliderId
   * @param {string} payload.id
   */
  deleteAwakening: ({ commit }, payload) => {
    commit('DELETE_AWAKENING', payload)
  },
  /**
   * Delete a slider
   *
   * @param {ActionContext} context
   * @param {String} id
   */
  deleteSlider: ({ commit }, id) => {
    commit('DELETE_SLIDER', id)
  },
  /**
   * Init the sliders with values saved
   *
   * @param commit
   * @param {Object} sleep - values returned from the JSON in the database
   * @param rest
   */
  loadSliders: ({ commit }, { sleep, ...rest }) => {
    commit('LOAD_SLIDERS', rest)
    commit('LOAD_SLEEP_SLIDER', { sleep, today: rest.today })
  },
  resetSliders: ({ commit }) => {
    commit('RESET_SLIDERS')
  },
  /**
   * Update the internal cursor for the sleep slider
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {String} payload.type
   * @param {String} payload.id
   * @param {String} payload.sleepId
   * @param {Number|String} payload.minutes
   * @param {Number|String} payload.hours
   * @param {Date} payload.today
   * @param {Boolean} [payload.checkOthers]
   * @param {Boolean} [payload.isIncrease]
   */
  sleepHourChange: ({ commit, state, dispatch }, { checkOthers, isIncrease, ...rest }) => {
    if (rest.sleepId && checkOthers) {
      const sliderUpdated = state.sliders.list.find(slider => slider.id === rest.id)
      const sleepUpdatedIndex = sliderUpdated.sleeps.findIndex(sleep => sleep.id === rest.sleepId)
      const stepNumber = sliderUpdated.sleeps[sleepUpdatedIndex][rest.type].stepNumber
      const typeInverse = rest.type === 'start' ? 'end' : 'start'

      // La valeur augmente
      if (isIncrease) {
        if (typeInverse === 'end' && sliderUpdated.sleeps[sleepUpdatedIndex][typeInverse].stepNumber < stepNumber) {
          dispatch('sleepHourChange', { ...rest, type: typeInverse })
        }

        if (sleepUpdatedIndex !== (sliderUpdated.sleeps.length - 1)) {
          sliderUpdated.sleeps.slice(sleepUpdatedIndex + 1)
                       .forEach(sleep => {
                         if (sleep.start.stepNumber < stepNumber) {
                           dispatch('sleepHourChange', { ...rest, type: 'start', sleepId: sleep.id })
                         }
                         if (sleep.end.stepNumber < stepNumber) {
                           dispatch('sleepHourChange', { ...rest, type: 'end', sleepId: sleep.id })
                         }
                       })
        }
        // La valeur diminue
      } else {
        if (typeInverse === 'start' && sliderUpdated.sleeps[sleepUpdatedIndex][typeInverse].stepNumber > stepNumber) {
          dispatch('sleepHourChange', { ...rest, type: typeInverse })
        }

        if (sleepUpdatedIndex !== 0) {
          sliderUpdated.sleeps.slice(0, sleepUpdatedIndex)
                       .forEach(sleep => {
                         if (sleep.start.stepNumber > stepNumber) {
                           dispatch('sleepHourChange', { ...rest, type: 'start', sleepId: sleep.id })
                         }
                         if (sleep.end.stepNumber > stepNumber) {
                           dispatch('sleepHourChange', { ...rest, type: 'end', sleepId: sleep.id })
                         }
                       })
        }
      }
    }

    commit('SLEEP_HOUR_CHANGE', rest)
  },
  /**
   * Update a field in the cursor in the slider
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {String} payload.sliderId
   * @param {String} [payload.sleepId]
   * @param {String} payload.cursorName
   * @param {String} payload.field
   * @param {String|Number} payload.value
   */
  updateCursorSlider: ({ commit }, payload) => {
    commit('UPDATE_CURSOR_SLIDER', payload)
  },
  /**
   * Update the DashArray and the DashOffset of the slider
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {String} payload.dashArray
   * @param {String} payload.dashOffset
   * @param {String} payload.sliderId
   */
  updateArrayOffset: ({ commit }, payload) => {
    commit('UPDATE_ARRAY_OFFSET', payload)
  },
  /**
   * Update the data for the slider cursor.
   *
   * @param {ActionContext} context
   * @param {Object} newValues
   * @param {String} newValues.id
   * @param {Number} newValues.hour
   * @param {Number} newValues.day
   * @param {Date} newValues.today
   */
  updateSliderHourAndDate: ({ commit }, { id, day, hour, today }) => {
    const { year, month, date } = getHumanDate(day, today)

    commit(
      'UPDATE_SLIDER_HOUR_DATE',
      {
        id,
        hour,
        day,
        humanHour: typeof hour === 'number' ? getHumanHour(hour.toFixed(2)
                                                               .split('.')) : '',
        humanDay: `${year}-${month}-${date}`,
      },
    )
  },
}

export const SliderMutations = {
  /**
   *
   * @param state
   * @param {Object} payload
   * @param {String} payload.type
   * @param {Date} payload.today
   * @constructor
   */
  ADD_SLIDER: (state, { type, today }) => {
    const types = {
      lumino: true,
      nap: true,
      sleep: false,
    }
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const isSimple = types[type]

    let newSlider = {
      start: {
        d: -1,
        hd: getLocaleISOString(yesterday),
        h: 6,
        hh: '06:00',
      },
      end: {
        d: -1,
        hd: getLocaleISOString(yesterday),
        h: 6,
        hh: '06:00',
      },
      dashOffset: 0,
      dashArray: 0,
      id: `${type}__${state.sliders.i++}`,
      isSimple,
      type,
    }

    if (!isSimple) {
      newSlider.i = 0
      newSlider.sleeps = [{
        id: newSlider.id + '-sub__' + newSlider.i++,
        start: {
          d: -1,
          hd: getLocaleISOString(yesterday),
          h: 6,
          hh: '06:00',
          x: 0,
          stepNumber: 0,
        },
        end: {
          d: -1,
          hd: getLocaleISOString(yesterday),
          h: 6,
          hh: '06:00',
          x: 0,
          stepNumber: 0,
        },
      }]
    }

    state.sliders.list.push(newSlider)
  },
  ADD_SLEEP: (state, sliderId) => {
    const sliderUpdated = state.sliders.list.find(slider => slider.id === sliderId)

    const hasOtherSubSleepPeriod = sliderUpdated.sleeps.length !== 0
    const previousSleepPeriod = sliderUpdated.sleeps[sliderUpdated.sleeps.length - 1]
    const newSlider = {
      d: hasOtherSubSleepPeriod ? previousSleepPeriod.end.d : sliderUpdated.sleep.d,
      hd: hasOtherSubSleepPeriod ? previousSleepPeriod.end.hd : sliderUpdated.sleep.hd,
      h: hasOtherSubSleepPeriod ? previousSleepPeriod.end.h : sliderUpdated.sleep.h,
      hh: hasOtherSubSleepPeriod ? previousSleepPeriod.end.hh : sliderUpdated.sleep.hh,
      x: hasOtherSubSleepPeriod ? previousSleepPeriod.end.x : sliderUpdated.sleep.x,
      stepNumber: hasOtherSubSleepPeriod ? previousSleepPeriod.end.stepNumber : sliderUpdated.sleep.stepNumber,
    }

    sliderUpdated.sleeps.push({
      id: sliderUpdated.id + '-sub__' + sliderUpdated.i++,
      start: { ...newSlider },
      end: { ...newSlider },
    })
  },
  DELETE_AWAKENING: (state, { sliderId, id }) => {
    const slider = state.sliders.list.find(slider => slider.id === sliderId)
    slider.sleeps.splice(slider.sleeps.findIndex(awakening => awakening.id === id), 1)
  },
  DELETE_SLIDER: (state, id) => {
    state.sliders.list.splice(
      state.sliders.list.findIndex(slider => slider.id === id),
      1,
    )
  },
  LOAD_SLIDERS: (state, { nap, lumino, today }) => {
    state.sliders.list.splice(0, state.sliders.list.length)

    const initSlider = (toDatetime, fromDatetime, type) => {
      const [dateFrom, hourFrom] = fromDatetime.split(' ')
      const [hFrom, mFrom] = hourFrom.split(':')

      const [dateTo, hourTo] = toDatetime.split(' ')
      const [hTo, mTo] = hourTo.split(':')

      return {
        start: {
          d: dateEqual(new Date(dateFrom), today) ? 1 : -1,
          hd: dateFrom,
          h: parseFloat(`${hFrom}.${mFrom * 5 / 3}`),
          hh: hourFrom,
        },
        end: {
          d: dateEqual(new Date(dateTo), today) ? 1 : -1,
          hd: dateTo,
          h: parseFloat(`${hTo}.${mTo * 5 / 3}`),
          hh: hourTo,
        },
        dashOffset: 0,
        dashArray: 0,
        id: `${type}__${state.sliders.i++}`,
        isSimple: true,
        type,
      }
    }

    nap.forEach(({ to_datetime, from_datetime }) => {
      state.sliders.list.push(initSlider(to_datetime, from_datetime, 'nap'))
    })

    lumino.forEach(({ to_datetime, from_datetime }) => {
      state.sliders.list.push(initSlider(to_datetime, from_datetime, 'lumino'))
    })
  },
  /**
   *
   * @param state
   * @param sleep
   * @param today
   * @constructor
   */
  LOAD_SLEEP_SLIDER: (state, { sleep, today }) => {
    if (sleep.length) {
      const transformDateValueToSliderValue = datetime => {
        const [date, hour] = datetime.split(' ')
        const [h, m] = hour.split(':')
        return {
          d: dateEqual(new Date(date), today) ? 1 : -1,
          hd: date,
          h: parseFloat(`${h}.${m * 5 / 3}`),
          hh: hour,
          x: 0,
          stepNumber: 0,
        }
      }

      const sliderId = `sleep__${state.sliders.i++}`
      let sleepSlider = {
        id: sliderId,
        type: 'sleep',
        dashOffset: 0,
        dashArray: 0,
        isSimple: false,
        sleeps: [],
        start: {},
        end: {},
        i: 0,
      }

      // Une seule période sur la nuit
      if (sleep.length === 1) {
        const { from_datetime, asleep, wakeup, to_datetime } = sleep[0]
        sleepSlider.start = transformDateValueToSliderValue(from_datetime)
        sleepSlider.end = transformDateValueToSliderValue(wakeup)
        sleepSlider.sleeps.push({
          id: sleepSlider.id + '-sub__' + sleepSlider.i++,
          start: transformDateValueToSliderValue(asleep),
          end: transformDateValueToSliderValue(to_datetime),
        })
      } else {
        sleep.forEach((values, index) => {
          if (index === 0) {
            const { from_datetime, asleep, to_datetime } = values
            sleepSlider.start = transformDateValueToSliderValue(from_datetime)
            sleepSlider.sleeps.push({
              id: sleepSlider.id + '-sub__' + sleepSlider.i++,
              start: transformDateValueToSliderValue(asleep),
              end: transformDateValueToSliderValue(to_datetime),
            })
          } else if (index === sleep.length - 1) {
            const { from_datetime, wakeup, to_datetime } = values
            sleepSlider.sleeps.push({
              id: sleepSlider.id + '-sub__' + sleepSlider.i++,
              start: transformDateValueToSliderValue(from_datetime),
              end: transformDateValueToSliderValue(to_datetime),
            })
            sleepSlider.end = transformDateValueToSliderValue(wakeup)
          } else {
            const { from_datetime, to_datetime } = values
            sleepSlider.sleeps.push({
              id: sleepSlider.id + '-sub__' + sleepSlider.i++,
              start: transformDateValueToSliderValue(from_datetime),
              end: transformDateValueToSliderValue(to_datetime),
            })
          }
        })
      }

      state.sliders.list.push(sleepSlider)
    }
  },
  RESET_SLIDERS: state => {
    state.sliders.list.splice(0, state.sliders.list.length)
    state.sliders.i = 0
  },
  SLEEP_HOUR_CHANGE: (state, { type, id, sleepId, minutes, hours, today }) => {
    const slider = state.sliders.list.find(slider => slider.id === id)

    if (sleepId) {
      const sleep = slider.sleeps.find(sleep => sleep.id === sleepId)
      calculateHourAndDate(sleep[type], hours, minutes, type, slider, today)
    } else {
      calculateHourAndDate(slider[type], hours, minutes, type, slider, today)
    }
  },
  UPDATE_ARRAY_OFFSET: (state, { dashArray, dashOffset, sliderId }) => {
    const sliderUpdated = state.sliders.list.find(slider => slider.id === sliderId)
    sliderUpdated.dashArray = dashArray
    sliderUpdated.dashOffset = dashOffset
  },
  UPDATE_CURSOR_SLIDER: (state, { sliderId, sleepId, cursorName, field, value }) => {
    let itemUpdated = state.sliders.list.find(slider => slider.id === sliderId)
    if (sleepId) {
      itemUpdated = itemUpdated.sleeps.find(awakening => awakening.id === sleepId)
    }
    itemUpdated[cursorName][field] = value
  },
  UPDATE_SLIDER_HOUR_DATE: (state, newValues) => {
    const [id, type] = newValues.id.split('-')
    const sliderUpdated = state.sliders.list.find(slider => slider.id === id)

    const checkOtherValues = ({ day, hour, humanHour, humanDay }, otherObject, isStart) => {
      if (isStart) {
        if (day > otherObject.d || (day === otherObject.d && hour > otherObject.h)) {
          otherObject.h = hour
          otherObject.hh = humanHour
          otherObject.d = day
          otherObject.hd = humanDay
        }
      } else {
        if (day < otherObject.d || (day === otherObject.d && hour < otherObject.h)) {
          otherObject.h = hour
          otherObject.hh = humanHour
          otherObject.d = day
          otherObject.hd = humanDay
        }
      }
    }

    if (type === 'start') {
      checkOtherValues(newValues, sliderUpdated.end, true)
      if (sliderUpdated.sleeps) {
        sliderUpdated.sleeps.forEach(sleep => {
          checkOtherValues(newValues, sleep.start, true)
          checkOtherValues(newValues, sleep.end, true)
        })
      }
    } else {
      checkOtherValues(newValues, sliderUpdated.start, false)
      if (sliderUpdated.sleeps) {
        sliderUpdated.sleeps.forEach(sleep => {
          checkOtherValues(newValues, sleep.start, false)
          checkOtherValues(newValues, sleep.end, false)
        })
      }
    }

    sliderUpdated[type].h = newValues.hour
    sliderUpdated[type].hh = newValues.humanHour
    sliderUpdated[type].d = newValues.day
    sliderUpdated[type].hd = newValues.humanDay
  },
}
