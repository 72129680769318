<template>
  <v-snackbar :value="snackbarOptionsLocale"
              :timeout="snackbarOptions.timeout"
              :color="snackbarOptions.color"
              content-class="information__snackbar"
              elevation="12"
              multi-line
              @input="onHiddenSnackbar"
  >
    <div style="float:left;">
      <v-icon v-if="snackbarOptions.type === 'error'">mdi-alert</v-icon>
      <v-icon v-else>mdi-information</v-icon>
    </div>
    <div style="float:left">
      <span v-html="cleanMessage(snackbarOptions.message)"/>
    </div>

    <template v-slot:action="{ attrs }">
      <v-icon v-bind="attrs" @click="onHiddenSnackbar">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Snackbar',

  computed: {
    ...mapGetters([
      'snackbarOptions',
    ]),
    snackbarOptionsLocale () {
      return this.snackbarOptions.display
    },
  },

  methods: {
    ...mapActions(['displaySnackbar']),
    onHiddenSnackbar () {
      this.displaySnackbar({ status: false })
    },

    cleanMessage(message) {
      if(Array.isArray(message)) {
        return "<ul><li>" + message.join("</li><li>") + "</li></ul>"
      }

      return message
    }
  },
}
</script>

<style lang="scss" scoped>
.information__snackbar {
  i {
    margin-right: 15px;
  }
}
</style>
