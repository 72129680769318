/**
 * Checks if the first date is superior or superior or equal to the second date
 *
 * @param {Date} date1
 * @param {Date} date2
 * @param {Boolean} [equal=false]
 * @return {Boolean}
 */
export const dateSuperior = (date1, date2, equal = false) => {
  let [day1, month1, year1] = date1.toLocaleDateString()
                                   .split('/')
  const [day2, month2, year2] = date2.toLocaleDateString()
                                     .split('/')

  if (year1 < year2) {
    return false
  } else if (year1 === year2) {
    if (month1 < month2) {
      return false
    } else if (month1 === month2) {
      return equal ? day1 >= day2 : day1 > day2
    } else {
      return true
    }
  } else {
    return true
  }
}

/**
 * Checks if date is inferior or inferior or equal
 *
 * @param {Date} date1
 * @param {Date} date2
 * @param {Boolean} [equal=false]
 * @return {Boolean}
 */
export const dateInferior = (date1, date2, equal = false) => {
  let [day1, month1, year1] = date1.toLocaleDateString()
                                   .split('/')
  const [day2, month2, year2] = date2.toLocaleDateString()
                                     .split('/')

  if (year1 > year2) {
    return false
  } else if (year1 === year2) {
    if (month1 > month2) {
      return false
    } else if (month1 === month2) {
      return equal ? day1 <= day2 : day1 < day2
    } else {
      return true
    }
  } else {
    return true
  }
}

/**
 * Checks if the first date is equal of the second date
 *
 * @param {Date} date1
 * @param {Date} date2
 * @return {Boolean}
 */
export const dateEqual = (date1, date2) => {
  let [day1, month1, year1] = date1.toLocaleDateString()
                                   .split('/')
  const [day2, month2, year2] = date2.toLocaleDateString()
                                     .split('/')

  if (year1 !== year2) {
    return false
  }

  if (month1 !== month2) {
    return false
  }

  return day1 === day2
}

/**
 * Checks if the first date is different of the second date
 *
 * @param {Date} date1
 * @param {Date} date2
 * @return {Boolean}
 */
export const dateDifferent = (date1, date2) => {
  let [day1, month1, year1] = date1.toLocaleDateString()
                                   .split('/')
  const [day2, month2, year2] = date2.toLocaleDateString()
                                     .split('/')

  if (year1 !== year2) {
    return true
  }

  if (month1 !== month2) {
    return true
  }

  return day1 !== day2
}

/**
 * Checks if the first date is different of the second date
 *
 * @param {Date} dateSup
 * @param {Date} dateInf
 * @return {Number}
 */
export const dateDifference = (dateSup, dateInf) => {
  return Math.floor((dateSup - dateInf) / (3600 * 24 * 1000))
}
