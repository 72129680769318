<template>
  <v-navigation-drawer
      :value="display"
      @input="$emit('update:display', $event)"
      fixed
      temporary
      touchless
  >
    <v-list nav dense>
      <v-list-item-group>
        <v-list-item
            v-for="item in items"
            :key="item.id"
            :to="{ name: item.routeName, params: item.params }"
        >
          <v-list-item-title>
            {{ $t(item.text) }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="userIsConnected" @click="onLogout">
          <v-list-item-title>
            {{ $t('navbar.account.logoutAction') }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <section v-if="userIsConnected">
      <v-select
          :value="
          userConnected.groupSelected ? userConnected.groupSelected.id : null
        "
          :label="$t('navbar.selects.group')"
          :disabled="userConnected.groups.length === 1"
          :items="selects.groups"
          solo
          dense
          @input="selectGroup($event)"
      />
      <v-select
          :value="
          userConnected.studySelected ? userConnected.studySelected.id : null
        "
          :label="$t('navbar.selects.study')"
          :disabled="userConnected.studies.length === 1"
          :items="selects.studies"
          solo
          dense
          @input="selectStudy($event)"
      />
    </section>
    <section>
      <v-select
          v-model="$root.$i18n.locale"
          :items="languages"
          item-value="code"
          item-text="title"
          solo
          dense
          @change="changeLanguage"
      >
        <template v-slot:item="{ item }">
          <img :src="item.flagSrc"/>
          <span> {{ item.title }}</span>
        </template>
      </v-select>
    </section>
  </v-navigation-drawer>
</template>

<script>
import NavbarMixin from '@/components/Template/NavbarMixin'
import { myStatus } from '@/store/modules/User'
import { mapActions } from 'vuex'

export default {
  name: 'NavigationDrawer',

  mixins: [NavbarMixin],

  props: {
    display: {
      required: true,
      type: Boolean,
    },
  },

  data: () => ({
    items: [],
    // TODO: move languages & refactor languages settings
    languages: [
      {
        code: 'fr',
        title: 'Français',
        flagSrc: require('../../assets/img/flag_fr.png'),
      },
      {
        code: 'en',
        title: 'English',
        flagSrc: require('../../assets/img/flag_gb.png'),
      },
    ],
  }),

  watch: {
    userIsConnected: function () {
      this.createNavigation()
    },
    userConnected: {
      deep: true,
      handler: function () {
        if (this.userConnected.id) {
          this.createNavigation()
        }
      },
    },
  },

  created () {
    this.createNavigation()
  },

  methods: {
    ...mapActions(['displaySnackbar']),
    createNavigation () {
      if (!this.userIsConnected) {
        this.items = [
          ...this.menus.accountNotConnected,
          ...this.menus.infoNotConnected,
        ]

        this.items = this.items.filter(
            item => item.routeName !== 'Presentation',
        )
      } else {
        this.items = [...this.menus.account]

        if (myStatus(['PAT']) && this.userConnected.id) {
          const [dayCalendar, SurveyDayList] = this.menus.calendar
          this.items = [
            ...this.items,
            ...[
              dayCalendar,
              {
                ...SurveyDayList,
                params: {
                  ...SurveyDayList.params,
                  userId: this.userConnected.id,
                },
              },
            ],
          ]
        }

        this.items = [...this.items, ...this.menus.info]
      }

      this.items = this.items.filter(item => item.action !== 'logout')
    },
    onLogout () {
      this.logout()

      this.displaySnackbar({
        status: true,
        type: 'info',
        text: this.$t('security.login.alert.info'),
      })

      this.$router.push({ name: 'Login' })
    },
    changeLanguage (code) {
      // global app
      this.$root.$i18n.locale = code
      // vuetify
      this.$vuetify.lang.current = code
      this.createNavigation()
      this.$emit('update:display', false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.v-navigation-drawer {
  z-index: 500;
  display: block;

  @media all and (min-width: $s) {
    display: none;
  }

  .v-list-item {
    a, p {
      width: 100%;
      height: 100%;
      cursor: pointer;
      margin: 0;
      align-items: center;
      color: $primaryColorText;
      font-size: 20px;
      text-decoration: none;
    }

    .v-list-item__title {
      font-size: 20px;
    }
  }
}
</style>
