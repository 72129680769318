export const humanDate = humanDate => {
  const localeHumanDate = (new Date(humanDate)).toLocaleDateString(
    navigator.language || 'fr-FR',
    {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    },
  )

  return localeHumanDate[0].toUpperCase() + localeHumanDate.slice(1)
}
