'use strict'

import i18n from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import Vue from 'vue'


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

/**
 * Checks if the token is expired.
 * Recovers the payload, decodes the payload with Base64 decode method.
 * Checks the exp field with the current timestamp.
 *
 * @param token {String}
 * @return {boolean}
 */
const expired = token => {
  return JSON.parse(atob(token.split('.')[1])).exp < Math.trunc(Date.now() / 1000) + 20
}

/**
 * If the access token is expired, the plugin uses this method to refresh this token with the refresh token.
 * Do a axios call on the refresh URL to JWT Server with the refresh token in the data.
 *
 * @return {Promise<void>}
 */
const refresh = async () => {
  await axios({
    url: `${process.env.VUE_APP_API_URL}/token/refresh/`,
    method: 'POST',
    data: { refresh: localStorage.getItem('JWT_REFRESH') },
  })
    .then(response => {
      localStorage.setItem('JWT_ACCESS', response.data.access)
    })
    .catch(error => {
      console.error(error)
    })
}

export const _axios = axios.create(config)

_axios.interceptors.request.use(
  async function (config) {
    if (process.env.NODE_ENV === 'test') {
        return config
    }

    /**
     * If the URL contains "/token/" the plugin lets the request pass.
     * It's a security for axios call for login or refresh call.
     */
    if (config.url.match(/\/token\//)) {
      return config
    }

    if (localStorage.getItem('JWT_ACCESS') !== null) {
      if (expired(localStorage.getItem('JWT_ACCESS'))) {
        if (localStorage.getItem('JWT_REFRESH') !== null && !expired(localStorage.getItem('JWT_REFRESH'))) {
          await refresh()
        }
        else {
          await router.push({ name: 'Login' })
        }
      }
      config.headers.Authorization = `Bearer ${localStorage.getItem('JWT_ACCESS')}`
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  async function (error) {
    let message = i18n.t('errors.networkError')

    if (error.response.status !== 401 || error.response.data.code !== 'token_not_valid') {
      if (error.response) {
          if (error.response.data['errors'] !== undefined) {
            message = error.response.data['errors'] !== undefined ? error.response.data['errors'] : error
          } else if (typeof error.response.data === "object" && error.response.data !== null) {
              message = []
              Object.keys(error.response.data).forEach(key => {
                message.push(key + " : " + error.response.data[key])
              })
          } else {
            message = error
          }
      }

      store.dispatch(
        'displaySnackbar', {
           status: true,
           type: 'error',
           timeout: 10000,
           text: message,
        }, {root: true},
      )
      return Promise.reject(error)
    }

    // Token refresh error : bail out
    if (error.config.url === `${process.env.VUE_APP_API_URL}/token/refresh/`) {
      await store.dispatch('logout');

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Test the jwt_refresh token & refresh the jwt_token
    if (localStorage.getItem('JWT_REFRESH') !== null && !expired(localStorage.getItem('JWT_REFRESH'))) {
        await refresh()
    }
    else {
        await router.push({ name: 'Login' })
    }

    // Retry the initial request with the new jwt_token
    const config = error.config;
    config.headers.Authorization = `Bearer ${localStorage.getItem('JWT_ACCESS')}`

    return new Promise((resolve, reject) => {
      axios.request(config).then(response => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    });
  },
)

const Plugin = {}

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      },
    },
    $axios: {
      get () {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
