import Vue from 'vue';
import en from "vuetify/es5/locale/en";
import fr from 'vuetify/es5/locale/fr';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr, en },
    current: navigator.language.split('-')[0] || 'fr',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4f5ab4',
        secondary: '#de791d',
      },
    },
  },
})
