import { _axios } from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import router from '@/router'
import Converter from '@/store/Converter'
import { myStatus, UserEmpty } from '@/store/modules/User'

/**
 * Return an object with the different part of the JWT
 *
 * @param {String} jwt
 * @returns {{head: {typ: String, alg: String}, signature: String, body: {JWTBody}}}
 * @constructor
 */
export const JWTTranslation = jwt => {
  const [head, body, signature] = jwt.split('.')
  const {
    token_type: tokenType,
    user_id: userId,
    first_name: firstName,
    last_name: lastName,
    has_temp_password: hasTempPassword,
    has_expired_password: hasExpiredPassword,
    profile_id: profileId,
    profile_name: profileName,
    ...rest
  } = JSON.parse(atob(body))

  return {
    head: JSON.parse(atob(head)),
    body: {
      tokenType,
      userId,
      firstName,
      lastName,
      hasTempPassword,
      hasExpiredPassword,
      profileId,
      profileName, ...rest,
    },
    signature,
  }
}

/**
 * Return if jwt expired
 *
 * @param {String} jwt
 * @returns {Boolean}
 * @constructor
 */
export const JWTExpired = jwt => {
  if (jwt === null) return true
  return (new Date()).getTime() > (JWTTranslation(jwt).body.exp * 1000)
}

/**
 * @typedef {Object} UserConnectedOnirosJS
 * @property {Number} id
 * @property {String} username
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} email
 * @property {Boolean} hasTempPassword
 * @property {String} phone
 * @property {Object} region
 * @property {String} region.code
 * @property {String} region.label
 * @property {UserGroupJS[]} groups
 * @property {Boolean} isActive
 * @property {UserOnirosJS} referent
 * @property {Number} alarmTime
 * @property {Number[]} studies - list of the studies ID
 * @property {Number} currentStudyDay
 * @property {Date} inclusionDate
 * @property {Array} groupSelected
 * @property {String} groupSelected.humanName
 * @property {Number} groupSelected.id
 * @property {String} groupSelected.name
 * @property {Array} studySelected
 * @property {Boolean} studySelected.active
 * @property {Number} studySelected.code
 * @property {Date} studySelected.created
 * @property {String} studySelected.email
 * @property {Number} studySelected.id
 * @property {String} studySelected.label
 * @property {Date} studySelected.lastUpdated
 * @property {String} studySelected.studyImg
 * @property {String} studySelected.studyPdf
 * @property {Object} settings
 */
/**
 * @type {UserConnectedOnirosJS}
 */
export const UserConnectedEmpty = {
  ...UserEmpty,
  groupSelected: {
    humanName: '',
    id: null,
    name: '',
  },
  studySelected: {
    active: false,
    code: null,
    created: null,
    email: '',
    id: null,
    label: '',
    lastUpdated: null,
    studyImg: null,
    studyPdf: null,
  },
}

/**
 * @typedef {Object} JWTBody
 * @property {String} jti
 * @property {Number} exp
 * @property {String} tokenType
 * @property {Number} userId
 * @property {String} firstName
 * @property {String} lastName
 * @property {Boolean} hasTempPassword
 * @property {Boolean} hasExpiredPassword
 * @property {Number} profileId
 * @property {String} profileName
 */
/**
 * @type {JWTBody}
 */
export const JWTBodyEmpty = {
  jti: '',
  exp: null,
  tokenType: '',
  userId: null,
  firstName: '',
  lastName: '',
  hasTempPassword: false,
  hasExpiredPassword: false,
  profileId: null,
  profileName: '',
}

/**
 * @typedef {Object} SecurityState
 * @property {JWTBody} accessJWTBody
 * @property {UserConnectedOnirosJS} userConnected
 * @property {Boolean} userIsConnected
 */
/**
 * @type {SecurityState}
 */
const state = {
  accessJWTBody: JWTBodyEmpty,
  userConnected: UserConnectedEmpty,
  userIsConnected: false,
}

const getters = {
  /**
   * @param {SecurityState} state
   * @return {JWTBody}
   */
  accessJWTBody: state => state.accessJWTBody,
  isPatient: () => myStatus(['PAT']),
  isTechnician: state => state.userConnected.groups.some(group => group.name === 'TEC'),
  /**
   * @param {SecurityState} state
   * @return {UserConnectedOnirosJS}
   */
  userConnected: state => state.userConnected,
  /**
   * @param {SecurityState} state
   * @return {boolean}
   */
  userIsConnected: state => state.userIsConnected,
}

const actions = {
  /**
   * Check if the refresh token defined and expired.
   * Check if the access token defined.
   *
   * @param {ActionContext} context
   */
  checkConnectionStatus: ({ commit, dispatch }) => {
    const jwtAccess = localStorage.getItem('JWT_ACCESS')
    const jwtRefresh = localStorage.getItem('JWT_REFRESH')

    if ((!jwtAccess && jwtRefresh) || (!jwtRefresh && jwtAccess)) {
      dispatch('logout')
    } else if (jwtRefresh) {
      const expired = JWTExpired(jwtRefresh)
      if (expired) {
        dispatch('logout')
      } else {
        commit('UPDATE_CONNECTION_STATUS', true)
      }
    }
  },
  /**
   * Init the JWT body in the state to access of the value in all components
   */
  initJWTBody: ({ commit }) => {
    commit(
      'LOAD_JWT',
      localStorage.getItem('JWT_ACCESS')
      ? JWTTranslation(localStorage.getItem('JWT_ACCESS')).body
      : null,
    )
  },
  loadUserConnected: ({ state, rootState, commit, dispatch }) => {
    const userId = localStorage.getItem('JWT_ACCESS')
                   ? JWTTranslation(localStorage.getItem('JWT_ACCESS')).body.userId
                   : null

    if (userId !== null && state.userConnected.id !== userId) {
      return _axios.get(`users/${userId}`)
                   .then(response => {
                     return Promise.all([
                       dispatch('loadStudies', {}, true),
                       dispatch('loadGroups', {}, true),
                     ])
                                   .then(() => {
                                     // let userLoaded = { ...response.data }
                                     let userLoaded = { ...response.data }

                                     // If user is admin, he looks all studies
                                     if (myStatus('ADM')) {
                                       userLoaded.studies = [...rootState.Study.studies]
                                     } else {
                                       // else user looks only the related studies
                                       userLoaded.studies = userLoaded.studies.map(studyId => {
                                         return {
                                           ...rootState.Study.studies.find(study => {
                                             return study.id === studyId
                                           }),
                                         }
                                       })
                                     }

                                     // Add the correct group in the user with the ID
                                     userLoaded.groups = userLoaded.groups.map(groupUser => {
                                       return rootState.User.groups.find(group => group.id === groupUser.id)
                                     })

                                     // Checks for the study selected
                                     let selectedStudyId = localStorage.getItem('selectedStudyId')
                                     if (selectedStudyId) {
                                       let studyCheck = userLoaded.studies.find(study => {
                                         return study.id === parseInt(selectedStudyId)
                                       })
                                       if (studyCheck) {
                                         userLoaded.studySelected = studyCheck
                                       } else {
                                         // Wrong study in localeStorage
                                         localStorage.removeItem('selectedStudyId')
                                       }
                                     }

                                     if (userLoaded.studies.length === 1) {
                                       userLoaded.studySelected = userLoaded.studies.find(study => {
                                         return study.id === userLoaded.studies[0].id
                                       })
                                       localStorage.setItem('selectedStudyId', userLoaded.studySelected.id)
                                     } else {
                                       userLoaded.studySelected = null
                                     }

                                     // Checks for the group selected
                                     let selectedGroupId = localStorage.getItem('selectedGroupId')

                                     if (selectedGroupId) {
                                       let groupCheck = userLoaded.groups.find(group => {
                                         return group.id === parseInt(selectedGroupId)
                                       })
                                       if (groupCheck) {
                                         userLoaded.groupSelected = groupCheck
                                       } else {
                                         localStorage.removeItem('selectedGroupId')
                                         if (userLoaded.groups.length === 1) {
                                           userLoaded.groupSelected = userLoaded.groups.find(group => {
                                             return group.id === userLoaded.groups[0].id
                                           })
                                           localStorage.setItem('selectedGroupId', userLoaded.groupSelected.id)
                                         }
                                       }
                                     } else if (userLoaded.groups.length === 1) {
                                       userLoaded.groupSelected = userLoaded.groups.find(group => {
                                         return group.id === userLoaded.groups[0].id
                                       })
                                       localStorage.setItem('selectedGroupId', userLoaded.groupSelected.id)
                                     } else {
                                       userLoaded.groupSelected = null
                                     }

                                     // TODO: Fix in the backend api not in the front !
                                     if (userLoaded.settings === null) userLoaded.settings = {}

                                     commit('LOAD_USER_CONNECTED', Converter.ptj.user(userLoaded))
                                   })
                   })
    }
  },
  /**
   * Log an user
   *
   * @param {ActionContext} context
   * @param {Object} user
   * @param {String} user.password
   * @param {String} user.username
   * @returns {Promise<void>}
   */
  login: ({ dispatch }, user) => {
    return _axios.post('token/', user)
                 .then(response => {
                   localStorage.removeItem('JWT_ACCESS')
                   localStorage.removeItem('JWT_REFRESH')

                   localStorage.setItem('JWT_ACCESS', response.data.access)
                   localStorage.setItem('JWT_REFRESH', response.data.refresh)

                   dispatch('initJWTBody')
                 })
  },
  /**
   * Delete the tokens in the local storage
   */
  logout: ({ commit, state }) => {
    let remember = (state.userConnected.settings && state.userConnected.settings.rememberSelected)
    if (remember) {
      localStorage.removeItem('JWT_ACCESS')
      localStorage.removeItem('JWT_REFRESH')
    } else {
      localStorage.clear()
    }
    commit('RESET_STATE')
  },
  /**
   * Send a request for password recovery
   * The MED/TEC can send the request with the PAT ID
   * The user can send the request with his email
   *
   * @param {ActionContext} context
   * @param {Object} data
   * @param {String} data.id
   * @param {String} data.noRedirect
   * @returns {AxiosPromise<any>}
   */
  recoveryPassword: ({ dispatch }, data) => {
    const noError = () => {
      // Init a function for request validation
      // If it is the user who requests the password reset
      if (!data.noRedirect) {
        router.push({ name: 'Login' })
              .then(() => {
                dispatch(
                  'displaySnackbar', {
                    status: true,
                    type: 'info',
                    text: i18n.t('security.forgottenPassword.info'),
                  }, { root: true },
                )
              })
              .catch(() => {})
        // It is a MED or a TEc who requests the password reset for MED or PAT
      } else {
        dispatch(
          'displaySnackbar', {
            status: true,
            type: 'info',
            text: i18n.t('security.forgottenPassword.confirm'),
          }, { root: true },
        )
      }
    }

    return _axios.post('passwords/recovery/', data)
                 .then(() => {
                   noError()
                 })
                 .catch(error => {
                   `${error.response.status}`.match(/5[0-9]{2}/) ?
                   dispatch(
                     'displaySnackbar', {
                       status: true,
                       type: 'error',
                       text: i18n.t('general.alert.500'),
                     }, { root: true },
                   ) :
                   noError()
                 })
  },
  /**
   * Reset password if :
   * - password forgotten
   * - first connection
   * - password expired
   * - user wants to update password
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {String} payload.passwordModificationType - Can be : passwordExpired, passwordInit, passwordReset, passwordUpdate, passwordRecovery
   * @param {Object} payload.credentials
   * @param {String} credentials.password - New value for the password
   * @param {String} [credentials.oldPassword] - Old value for the password to check the identity
   * @param {String} [credentials.password1] - Version 1 of the password
   * @param {String} [credentials.password2] - Version 2 of the password
   * @param {String} [credentials.id] - UserList id
   * @param {String} token - Recovery token password
   * @returns {AxiosPromise<any>}
   */
  resetPassword: ({ dispatch }, { passwordModificationType, credentials, token }) => {
    const promiseAccept = () => {
      localStorage.removeItem('JWT_ACCESS')
      localStorage.removeItem('JWT_REFRESH')

      router.push({ name: 'Login' })
            .then(() => {
              dispatch(
                'displaySnackbar', {
                  status: true,
                  type: 'info',
                  text: i18n.t('security.login.alert.password'),
                }, { root: true },
              )
            })
            .catch(() => {})
    }

    const promiseError = error => {
      if (error.response.status === 404) {
        dispatch(
          'displaySnackbar', {
            status: true,
            type: 'error',
            text: i18n.t('security.resetPassword.alert.userNotFound'),
          }, { root: true },
        )
      } else if (`${error.response.status}`.match(/4[0-9]{2}/)) {
        dispatch(
          'displaySnackbar', {
            status: true,
            type: 'error',
            text: error.response.data.error,
          }, { root: true },
        )
      } else {
        dispatch(
          'displaySnackbar', {
            status: true,
            type: 'error',
            text: i18n.t('general.alert.500'),
          }, { root: true },
        )
      }
    }

    switch (passwordModificationType) {
      case 'passwordExpired':
      case 'passwordUpdate':
        return _axios.post(
          'passwords/update/', {
            id: credentials.id,
            password: credentials.password1,
            oldPassword: credentials.oldPassword,
          },
        )
                     .then(promiseAccept)
                     .catch(promiseError)
      case 'passwordInit':
        return _axios.post('passwords/init/', { id: credentials.id, password: credentials.password1 })
                     .then(promiseAccept)
                     .catch(promiseError)
      case 'passwordRecovery':
        return _axios.post(`passwords/recovery/${token}/`, credentials)
                     .then(promiseAccept)
                     .catch(promiseError)
    }
  },
  selectGroup: ({ commit, rootState }, groupId) => {
    commit('SELECT_GROUP', rootState.User.groups.find(group => group.id === groupId))
  },
  selectStudy: ({ commit, rootState }, studyId) => {
    commit('SELECT_STUDY', rootState.Study.studies.find(study => study.id === studyId))
  },
  /**
   * Send a request for login recovery
   * The user can send the request with his email
   *
   * @param {ActionContext} context
   * @param {Object} data
   * @param {String} data.id
   * @param {String} data.noRedirect
   * @returns {AxiosPromise<any>}
   */
  recoveryLogin: ({ dispatch }, data) => {
    const noError = () => {
      // Init a function for request validation
      // If it is the user who requests the login request
      if (!data.noRedirect) {
        router.push({ name: 'Login' })
              .then(() => {
                dispatch(
                  'displaySnackbar', {
                    status: true,
                    type: 'info',
                    text: i18n.t('security.forgottenLogin.info'),
                  }, { root: true },
                )
              })
              .catch(() => {})
        // It is a MED or a TEc who requests the password reset for MED or PAT
      } else {
        dispatch(
          'displaySnackbar', {
            status: true,
            type: 'info',
            text: i18n.t('security.forgottenLogin.confirm'),
          }, { root: true },
        )
      }
    }

    return _axios.post('login/recovery/', data)
                 .then(() => {
                   noError()
                 })
                 .catch(error => {
                   `${error.response.status}`.match(/5[0-9]{2}/) ?
                   dispatch(
                     'displaySnackbar', {
                       status: true,
                       type: 'error',
                       text: i18n.t('general.alert.500'),
                     }, { root: true },
                   ) :
                   noError()
                 })
  },
}

const mutations = {
  LOAD_JWT: (state, jwtBody) => {
    state.accessJWTBody = jwtBody ? { ...jwtBody } : { ...JWTBodyEmpty }
  },
  LOAD_USER_CONNECTED: (state, user) => {
    state.userConnected = { ...user }
  },
  RESET_STATE: state => {
    state.accessJWTBody = { ...JWTBodyEmpty }
    state.userConnected = { ...UserConnectedEmpty }
    state.userIsConnected = false
  },
  SELECT_GROUP: (state, groupSelected) => {
    state.userConnected.groupSelected = groupSelected
    if (groupSelected && state.userConnected.settings && state.userConnected.settings.rememberSelected) {
      localStorage.setItem('selectedGroupId', groupSelected.id)
    }
  },
  SELECT_STUDY: (state, studySelected) => {
    state.userConnected.studySelected = studySelected
    if (studySelected && state.userConnected.settings && state.userConnected.settings.rememberSelected) {
      localStorage.setItem('selectedStudyId', studySelected.id)
    }
  },
  UPDATE_CONNECTION_STATUS: (state, newStatus) => {
    state.userIsConnected = newStatus
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
