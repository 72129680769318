<template>
  <nav class="d-print-none" :class="{ prod_instance: isProdInstance }">
    <div>
      <router-link :to="{ name: userIsConnected ? 'Dashboard' : 'Login' }" :class="{ icon_link: !isProdInstance }">
        <logo width="110" height="20"/>
        <span v-if="!isProdInstance">de test</span>
      </router-link>

      <section v-if="userIsConnected">
        <v-select
            :value="
            userConnected.groupSelected ? userConnected.groupSelected.id : null
          "
            :label="$t('navbar.selects.group')"
            :disabled="userConnected.groups.length === 1"
            :items="selects.groups"
            solo
            dense
            ref="navbar_select-group"
            @input="selectGroup($event)"
        />
        <v-select
            :value="
            userConnected.studySelected ? userConnected.studySelected.id : null
          "
            :label="$t('navbar.selects.study')"
            :disabled="userConnected.studies.length === 1"
            :items="selects.studies"
            solo
            dense
            ref="navbar_select-study"
            @input="selectStudy($event)"
        />
      </section>

      <div>
        <router-link
            :to="{ name: 'Dashboard' }"
            v-if="userIsConnected"
            ref="navbar-buttons-dashboard"
        >
          <v-icon :class="{ route__selected: dashboardSelected }"
          >mdi-home
          </v-icon
          >
        </router-link>

        <navbar-menus
            :items="menus.calendar"
            v-if="userIsConnected && isPatient()"
            ref="navbar-buttons-patient_actions"
        >
          <u-icon
              name="us-calendar"
              :class="{ route__selected: calendarSelected }"
          />
        </navbar-menus>

        <navbar-menus
            :items="menus.account"
            v-if="userIsConnected"
            ref="navbar-buttons-account"
        >
          <v-icon :class="{ route__selected: accountSelected }"
          >mdi-account
          </v-icon
          >
        </navbar-menus>

        <navbar-menus
            :items="menus.accountNotConnected"
            v-if="!userIsConnected"
            ref="navbar-buttons-login"
        >
          <v-icon :class="{ route__selected: accountSelected }"
          >mdi-account
          </v-icon
          >
        </navbar-menus>

        <!-- <v-icon>mdi-bell</v-icon> TODO: faire le système de notif-->

        <navbar-menus
            :items="menus.info"
            v-if="userIsConnected"
            ref="navbar-buttons-info_connected"
        >
          <u-icon
              name="nv-interface-information"
              :class="{ route__selected: infoSelected }"
          />
        </navbar-menus>

        <navbar-menus
            :items="menus.infoNotConnected"
            v-if="!userIsConnected"
            ref="navbar-buttons-info_not_connected"
        >
          <u-icon
              name="nv-interface-information"
              :class="{ route__selected: infoSelected }"
          />
        </navbar-menus>
        <lang-switcher :languages="languages"/>
      </div>

      <v-btn
          small
          fab
          text
          @click="$emit('update:navigationDrawerDisplay', true)"
      >
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Logo from '@/components/Template/Logo'
import NavbarMenus from '@/components/Template/NavbarMenus'
import { myStatus } from '@/store/modules/User'
import NavbarMixin from '@/components/Template/NavbarMixin'
import LangSwitcher from '@/components/Template/LangSwitcher'

export default {
  name: 'Navbar',

  mixins: [NavbarMixin],

  components: { NavbarMenus, Logo, LangSwitcher },

  props: {
    navigationDrawerDisplay: {
      required: true,
      type: Boolean,
    },
  },

  data: () => ({
    connected: false,
    // TODO: move languages & refactor languages settings
    languages: [
      {
        code: 'fr',
        title: 'Français',
        flagSrc: require('../../assets/img/flag_fr.png'),
      },
      {
        code: 'en',
        title: 'English',
        flagSrc: require('../../assets/img/flag_gb.png'),
      },
    ],
  }),

  computed: {
    ...mapGetters(['groups', 'studies']),
    accountSelected () {
      return this.menus.account.some(
          item => item.routeName === this.$route.name,
      )
    },
    calendarSelected () {
      return this.menus.calendar.some(
          item => item.routeName === this.$route.name,
      )
    },
    dashboardSelected () {
      return this.$route.name === 'Dashboard'
    },
    infoSelected () {
      return this.menus.info.some(item => item.routeName === this.$route.name)
    },
    isProdInstance () {
      return process.env.VUE_APP_IS_PROD === 'true'
    },
  },

  watch: {
    userConnected: {
      deep: true,
      handler: function () {
        this.menus.calendar[1].params.userId = this.userConnected.id
      },
    },
  },

  created () {
    this.checkConnectionStatus()
    if (this.userIsConnected) {
      this.onLoadUserConnected()
    }
  },

  methods: {
    ...mapActions(['displaySnackbar']),
    isPatient () {
      return myStatus(['PAT'])
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

nav {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background-color: red;
  height: 61px;
  box-sizing: border-box;
  color: white;
  padding: 8px 16px;
  border-bottom: 5px solid rgb(114, 0, 0);

  &.prod_instance {
    background-color: $primaryColorDark;
    border-bottom: 5px solid $navbarBorderColor;

    > div {
      > div {
        i, .v-icon {
          &:after {
            background-color: $primaryColor;
          }
        }
      }
    }
  }

  &:not(.prod_instance) {
    .icon_link {
      svg {
        margin-right: 15px;
      }

      text-decoration: none;

      span {
        color: white;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    color: inherit;

    a:last-child, > a {
      display: flex;
      align-items: center;
      font-size: 40px;

      svg {
        z-index: 101;
        filter: brightness(600%) grayscale(1);
        cursor: pointer;

        &:hover::v-deep {
          .st0 {
            fill: white;
          }
        }
      }
    }

    > section {
      display: none;
      height: 40px;
      padding-top: 5px;

      @media all and (min-width: $s) {
        display: flex;
      }

      .v-input:first-of-type {
        margin-right: 20px;
      }
    }

    > div {
      display: none;
      justify-content: space-between;
      color: inherit;

      @media all and (min-width: $s) {
        display: flex;
      }

      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 40px;
        transition: background-color 100ms;
        border-radius: 4px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }

      i, .v-icon {
        color: inherit;
        width: 32px;
        height: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
          content: "";
          transform: scale(1.3);
          display: inline-block;
          position: absolute;
          width: 0;
          height: 5px;
          background-color: orange;
          z-index: 101;
          opacity: 1;
          border-radius: 0;
          top: 47px;
          left: 50%;
          transition: width 0.2s cubic-bezier(0.4, 0, 0.6, 1),
          left 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        }

        &.route__selected,
        &:hover {
          &:after {
            width: 32px;
            left: 0;
          }
        }
      }

      .nova,
      .symbol {
        font-size: 25px;
        height: 40px;
        width: 30px;
      }
    }

    > button {
      display: block;

      @media all and (min-width: $s) {
        display: none;
      }
    }
  }
}
</style>
