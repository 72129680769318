export default {
  methods: {
    /**
     * Calculates the difference in days between two dates
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {number}
     */
    getDayDiff (startDate, endDate) {
      const start = new Date(startDate.toLocaleDateString('en', { day: '2-digit', month: 'numeric', year: 'numeric' }))
      const end = new Date(endDate.toLocaleDateString('en', { day: '2-digit', month: 'numeric', year: 'numeric' }))

      return Math.round((end - start) / (60 * 60 * 24 * 1000))
    },
    /**
     *
     * @param {Date} date
     */
    getLocaleISOString (date) {
      const year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()

      month <= 8 ? month = `0${month + 1}` : month += 1
      day < 10 ? day = `0${day}` : day

      return `${year}-${month}-${day}`
    },
  },
}

export const datePrototypes = () => {
  Date.yesterday = () => new Date(new Date().setDate(new Date().getDate() - 1))
  Date.tomorrow = () => new Date(new Date().setDate(new Date().getDate() + 1))
}
