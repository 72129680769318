import { _axios } from '@/plugins/axios'

/**
 * @type {PatientState}
 */
const state = {
  results: [],
}

const getters = {
  /**
   * @param {PatientState} state
   * @return {Results[]}
   */
  results: state => state.results,
}

const actions = {
  loadPatientResults: ({ commit }, patientId) => {
    return _axios.get(`users/${patientId}/results`)
      .then(response => {
        commit('LOAD_PATIENT_RESULTS', response.data)
      })
  },
}

const mutations = {
  LOAD_PATIENT_RESULTS: (state, results) => {
    state.results = Object.assign({}, results.data)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
