import Vue from 'vue'
import Vuex from 'vuex'

import Agenda from '@/store/modules/Agenda'
import Calendar from '@/store/modules/Calendar'
import Patient from '@/store/modules/Patient'
import Region from '@/store/modules/Region'
import Security from '@/store/modules/Security'
import Study from '@/store/modules/Study'
import Survey from '@/store/modules/Survey'
import User from '@/store/modules/User'

Vue.use(Vuex)

export const snackbarOptionsEmpty = {
  color: '',
  display: false,
  message: '',
  type: '',
  priority: false,
}

export default new Vuex.Store({
  state: {
    snackbarOptions: { ...snackbarOptionsEmpty },
  },
  getters: {
    snackbarOptions: state => state.snackbarOptions,
  },
  actions: {
    /**
     * Display or hide the security error
     *
     * @param {ActionContext} context
     * @param {Object} displaySecurityError
     * @param {Boolean} displaySecurityError.status
     * @param {String} displaySecurityError.type ['']
     * @param {String} displaySecurityError.text ['']
     * @param {Boolean} displaySecurityError.priority [false]
     */
    displaySnackbar: ({ commit, state }, { status, type = '', text = '', priority = false, timeout = 5000 }) => {
      if (status) {
        if (!state.snackbarOptions.priority) {
          commit('LOAD_ERROR', { message: text, type, priority, timeout })
        }
      } else {
        commit('HIDE_ERROR')
      }
    },
  },
  mutations: {
    HIDE_ERROR: (state) => {
      state.snackbarOptions.message = ''
      state.snackbarOptions.color = ''
      state.snackbarOptions.type = ''
      state.snackbarOptions.display = false
      state.snackbarOptions.priority = false
    },
    LOAD_ERROR: (state, { message, type, priority, timeout }) => {
      state.snackbarOptions.message = message
      state.snackbarOptions.color = type === 'error' ? 'red accent-2' : 'blue'
      state.snackbarOptions.type = type
      state.snackbarOptions.display = true
      state.snackbarOptions.priority = priority
      state.snackbarOptions.timeout = timeout
    },
  },
  modules: {
    Agenda,
    Calendar,
    Patient,
    Region,
    Security,
    Study,
    Survey,
    User,
  },
})

