import { _axios } from '@/plugins/axios'
import Converter from '@/store/Converter'

/**
 * @typedef {Object} UserSurvey
 * @property {Number} answerId
 * @property {String} code - slug of survey
 * @property {Number} id
 * @property {String} label
 * @property {Number} status
 */
/**
 * @typedef {Object} UserSurveyDay
 * @property {Number} day
 * @property {UserSurvey[]} surveys
 */

/**
 * @typedef {Object} SurveyOnirosJS
 * @property {Number} id
 * @property {String} code
 * @property {String} label
 * @property {Boolean} active
 * @property {Date} created
 * @property {Date} lastUpdated
 * @property {Boolean} daily - If true, the survey not linked to a milestone
 * @property {Boolean} mandatory
 * @property {Boolean} atFirstLogin
 * @property {String} condition
 * @property {Number} Priority
 * @property {Object} questions
 * @property {Object} scoreFormulas
 * @property {Number} studyId
 * @property {Array|Number[]} milestonesId
 */
/**
 * @type {SurveyOnirosJS}
 */
const surveyEmpty = {
  id: null,
  code: '',
  label: '',
  active: false,
  created: null,
  lastUpdated: null,
  daily: false,
  mandatory: false,
  atFirstLogin: false,
  condition: '',
  Priority: null,
  questions: {},
  scoreFormulas: {},
  studyId: null,
  milestonesId: [],
  documents: [],
}
/**
 * @typedef {Object} SurveyResultOnirosJS
 * @property {Number} id
 * @property {Boolean} isCompleted
 * @property {Boolean} isDraft
 * @property {Boolean} isEmpty
 * @property {String} surveyCode
 * @property {Number} status - 0 = empty, 1 = draft, 2 = finish
 * @property {String} day - Milestone day
 * @property {Date} date
 * @property {Object} answers
 * @property {Object} scores
 * @property {Number} onirosuser
 * @property {Number} author - onirosuser author of the answers
 * @property {Number} survey
 * @property {Number} milestone
 */
export const surveyResultEmpty = {
  id: null,
  isCompleted: false,
  isDraft: false,
  isEmpty: false,
  surveyCode: '',
  status: 0,
  day: null,
  date: null,
  answers: [],
  scores: [],
  onirosuser: null,
  author: null,
  survey: null,
  milestone: null,
}

/**
 * @typedef {Object} SurveyState
 * @property {SurveyOnirosJS} survey
 * @property {SurveyResultOnirosJS} surveyResults
 * @property {SurveyOnirosJS[]} surveys
 * @property {UserSurvey[]} userForms
 * @property {UserSurveyDay[]} userSurveys
 */
/**
 * @type {SurveyState}
 */
const state = {
  survey: { ...surveyEmpty },
  surveyResults: {},
  surveys: [],
  userForms: [],
  userSurveys: [],
}

const getters = {
  /**
   * @param {SurveyState} state
   * @return {SurveyOnirosJS}
   */
  survey: state => state.survey,
  /**
   * @param {SurveyState} state
   * @return {SurveyResultOnirosJS}
   */
  surveyResults: state => state.surveyResults,
  /**
   * @param {SurveyState} state
   * @return {SurveyOnirosJS[]}
   */
  surveys: state => state.surveys,
  /**
   * @param {SurveyState} state
   * @return {UserSurvey[]}
   */
  userForms: state => state.userForms,
  /**
   * @param {SurveyState} state
   * @return {UserSurveyDay[]}
   */
  userSurveys: state => state.userSurveys,
}

const actions = {
  loadSurvey: ({ commit }, surveyId) => {
    return _axios.get(`surveys/${surveyId}`)
                 .then(response => {
                   commit('LOAD_SURVEY', response.data)
                 })
  },
  loadSurveyResults: ({ commit }, userId) => {
    return _axios.get(`users/${userId}/usersurveyanswers`)
                 .then(response => {
                   commit('LOAD_SURVEY_RESULTS', response.data)
                 })
  },
  loadUserForms: ({ commit }, userId) => {
    return _axios.get(`users/${userId}/forms`)
                 .then(response => {
                   commit('LOAD_USER_FORMS', response.data.data)
                 })
  },
  loadUserSurveys: ({ commit, dispatch }, userId) => {
    return _axios.get(`users/${userId}/surveys`)
                 .then(response => {
                   commit('LOAD_USER_SURVEYS', response.data.data)
                 })
                 .catch(error => {
                   if (error.response.status === 500) {
                     console.error(error.response)
                   } else {
                     dispatch(
                       'displaySnackbar', {
                         timeout: -1,
                         status: true,
                         type: 'error',
                         text: error.response.data.errors ? error.response.data.errors.join('<br />') : '',
                       }, { root: true },
                     )
                   }
                 })
  },
  resetSurveyResults: ({ commit }) => {
    commit('RESET_SURVEY_RESULTS')
  },
  saveResult: ({ commit }, { data, surveyResultId, userId }) => {
    if (surveyResultId) {
      return _axios.patch(`usersurveyanswers/${surveyResultId}`, data)
                   .then(response => {
                     commit('ADD_RESULT_SURVEY', response.data)
                   })
    } else {
      return _axios.post(`users/${userId}/usersurveyanswers`, data)
                   .then(response => {
                     commit('ADD_RESULT_SURVEY', response.data)
                   })
                   .catch(error => {
                     if (error.response.status !== 500) console.error(error.response)
                   })
    }
  },
  validSurvey: (context, { resultSurveyId, data }) => {

    return _axios.patch(`usersurveyanswers/${resultSurveyId}`, data)
  },
  resetSurvey: (context, { resultSurveyId }) => {
    return _axios.delete(`usersurveyanswers/${resultSurveyId}`)
  },
}

const mutations = {
  /**
   * @param state
   * @param {SurveyResultsOnirosPython} surveyResult
   * @constructor
   */
  ADD_RESULT_SURVEY: (state, surveyResult) => {
    if (state.surveyResults[surveyResult.survey_code]) {
      state.surveyResults[surveyResult.survey_code][surveyResult.day] = Converter.ptj.surveyResults(surveyResult)
    } else {
      state.surveyResults[surveyResult.survey_code] = {}
      state.surveyResults[surveyResult.survey_code][surveyResult.day] = Converter.ptj.surveyResults(surveyResult)
    }
  },
  LOAD_SURVEY: (state, survey) => {
    state.survey = { ...Converter.ptj.survey(survey) }
  },
  /**
   * @param state
   * @param {SurveyResultsOnirosPython[]} results
   * @constructor
   */
  LOAD_SURVEY_RESULTS: (state, results) => {
    results.forEach(result => {
      if (state.surveyResults[result.survey_code]) {
        state.surveyResults[result.survey_code][result.day] = Converter.ptj.surveyResults(result)
      } else {
        state.surveyResults[result.survey_code] = {}
        state.surveyResults[result.survey_code][result.day] = Converter.ptj.surveyResults(result)
      }
    })
  },
  LOAD_USER_FORMS: (state, userForms) => {
    state.userForms.splice(0, state.userForms.splice)

    userForms.forEach(userForm => {
      state.userForms.push({ ...Converter.ptj.userForm(userForm) })
    })
  },
  LOAD_USER_SURVEYS: (state, userSurveys) => {
    state.userSurveys.splice(0, state.userSurveys.length)

    userSurveys.forEach(userSurvey => {
      state.userSurveys.push({ ...Converter.ptj.userSurvey(userSurvey) })
    })
  },
  RESET_SURVEY_RESULTS: (state) => {
    state.surveyResults = {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
