/**
 * @typedef {Object} RegionState
 * @property {RegionJS[]} regions
 */
import { _axios } from '@/plugins/axios'

/**
 * @type {RegionState}
 */
const state = {
  /**
   * @typedef {Object} RegionJS
   * @property {String} code
   * @property {String} label
   */
  regions: [],
}

const getters = {
  /**
   * @param {RegionState} state
   * @returns {RegionJS[]}
   */
  regions: state => state.regions,
}

const actions = {
  /**
   * Load all the regions
   *
   * @param {Object} context
   * @returns {Promise<AxiosResponse<any>>}
   */
  loadRegions: ({ commit }) => {
    return _axios.get(`regions`)
                 .then(response => {
                   commit('LOAD_REGIONS', response.data)
                 })
  },
}

const mutations = {
  /**
   * @param {RegionState} state
   * @param {RegionJS[]} regions
   * @constructor
   */
  LOAD_REGIONS: (state, regions) => {
    state.regions.splice(0, state.regions.length)

    regions.forEach(region => {
      state.regions.push(Object.assign({}, region))
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
