<template>
  <v-menu offset-y bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark text v-bind="attrs" v-on="on">
        <slot/>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item
          v-for="item in items"
          :key="item.id"
          :to="item.routeName ? { name: item.routeName, params: item.params } : null"
          :ref="`navbar_link-${item.id}`"
          @click="onLogout(item)"
      >
        <v-list-item-title>
          {{ $t(item.text) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NavbarMenus',

  props: {
    items: {
      required: true,
      type: Array,
    },
  },

  methods: {
    ...mapActions(['displaySnackbar', 'logout']),
    isLink ({ routeName }) {
      return routeName !== null
    },
    onLogout (item) {
      if (item.action === 'logout') {
        this.logout()
        this.$router.push({ name: 'Login' })
            .then(() => {
              this.displaySnackbar({
                status: true,
                type: 'info',
                text: this.$t('security.login.alert.info'),
                priority: true,
              })
            })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/style";

button {
  min-width: 32px;
  width: 32px;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.theme--light.v-list {
  background-color: $navbarBorderColor;

  .v-list-item {
    &:hover {
      background-color: $primaryColorDark;
    }

    div,
    p {
      cursor: pointer;
      height: 100%;
      margin: 0;
      align-items: center;
      color: white;
      font-size: 20px;
      text-decoration: none;
    }
  }
}
</style>
