import { _axios } from '@/plugins/axios'
import Converter from '@/store/Converter'

/**
 * @typedef {Object} StudyState
 * @property {StudyOnirosJS[]} studies
 */
/**
 *
 * @type {StudyState}
 */
const state = {
  /**
   * @typedef {Object} StudyOnirosJS
   * @property {Number} id
   * @property {Number} code - Study code, used to create the PAT username
   * @property {String} label
   * @property {Date} created
   * @property {Date} lastUpdated
   * @property {Boolean} active
   * @property {String} email
   * @property {Boolean} studyImg
   * @property {Boolean} studyPdf
   */
  studies: [],
}

const getters = {
  studies: state => state.studies,
  studyImageURL: state => state.studyImageURL,
}

const actions = {
  loadStudies: ({ commit, state }) => {
    if (state.studies.length === 0) {
      return _axios.get('studies')
                   .then(response => {
                     commit('LOAD_STUDIES', response.data.map(study => Converter.ptj.study(study)))
                   })
    }
  },

  loadStudyImageURL: ({ commit }, id) => {
    if(id) {
      return _axios.get(`studies/${id}/img`, {responseType: 'blob'})
          .then(response => {
            commit('LOAD_STUDY_IMAGE_URL', response.data)
          })
          .catch(error => console.error(error))
    }
  },
}

const mutations = {
  LOAD_STUDIES: (state, studies) => {
    state.studies.splice(0, state.studies.length)

    studies.forEach(study => {
      state.studies.push(Object.assign({}, study))
    })
  },

  LOAD_STUDY_IMAGE_URL: (state, data) => {
    const file = new Blob([data], {type: 'image'});
    state.studyImageURL = URL.createObjectURL(file);
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
