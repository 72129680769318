<template>
  <i :class="[name, isNova ? 'nova' : 'symbol']"
     @click="$emit('click')"
     @keydown.enter="$emit('press-enter')"
     @keydown.space="$emit('press-space')"
     @keydown.delete="$emit('press-delete')"
  >
    <slot/>
  </i>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    isNova () {
      return this.name.match(/^nv-/)
    },
  },
}
</script>

<style lang="scss" scoped>
i {
  font-style: normal;

  &.nova {
    font-family: "NovaIcons", sans-serif;
  }

  &.symbol {
    font-family: "Unistra Symbol", sans-serif;
  }
}
</style>
