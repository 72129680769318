import { _axios } from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import Converter from '@/store/Converter'
import { JWTExpired, JWTTranslation } from '@/store/modules/Security'

/**
 * Checks user's status
 *
 * @param {Array} status
 * @returns {boolean}
 */
export const myStatus = status => {
  const refresh = localStorage.getItem('JWT_REFRESH')
  const access = localStorage.getItem('JWT_ACCESS')
  if (access === null || refresh === null || JWTExpired(refresh)) return false
  return status.includes(JWTTranslation(access).body.profileName)
}

/**
 * @type {UserOnirosJS}
 */
export const UserEmpty = {
  id: null,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  hasTempPassword: false,
  phone: '',
  region: { code: '', label: '' },
  groups: [{ id: null, name: '', humanName: '' }],
  isActive: false,
  referent: {
    id: null,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    hasTempPassword: false,
    region: { code: '', label: '' },
    groups: [{ id: null, name: '' }],
    referent: {
      lastName: '',
      firstName: '',
      email: '',
      phone: '',
    },
    alarmTime: null,
    studies: [],
    currentStudyDay: null,
    inclusionDate: null,
  },
  alarmTime: null,
  studies: [],
  currentStudyDay: null,
  inclusionDate: null,
  included: false,
  validCalendar: false,
  settings: {}
}

/**
 * Return the good name of the profile name with the profile code
 *
 * @param {String} code
 * @returns {string}
 */
export const humanProfile = code => {
  switch (code) {
    case 'MED':
      return i18n.t('user.profiles.MED')
    case 'CHR':
      return i18n.t('user.profiles.CHR')
    case 'TEC':
      return i18n.t('user.profiles.TEC')
    case 'PAT':
      return i18n.t('user.profiles.PAT')
    case 'ADM':
      return i18n.t('user.profiles.ADM')
  }
}

/**
 * Return the list of the group can create according to my status
 *
 * @returns {[]|String[]}
 */
export const groupCreationRights = () => {
  if (myStatus(['MED']) || myStatus(['CHR'])) {
    return ['PAT']
  } else if (myStatus(['TEC'])) {
    return ['MED', 'CHR', 'PAT']
  } else if (myStatus(['ADM'])) {
    return ['TEC', 'MED', 'CHR', 'PAT', 'ADM']
  } else {
    return []
  }
}

/**
 * @typedef {Object} UserGroupJS
 * @property {Number} id
 * @property {String} name
 */
/**
 * @typedef {Object} UserState
 * @property {UserGroupJS[]} groups
 * @property {GroupsIdJS} groupsId
 * @property {UserOnirosJS} user
 * @property {UserOnirosJS[]} users
 */
/**
 * @type {UserState}
 */
const state = {
  /**
   * @typedef {Object} UserGroupJS
   * @property {Number} id
   * @property {String} name
   * @property {String} humanName
   */
  groups: [],
  /**
   * @typedef {Object} GroupsIdJS
   * @property {Number} PAT
   * @property {Number} MED
   * @property {Number} CHR
   * @property {Number} TEC
   * @property {Number} ADM
   */
  groupsId: {
    PAT: null,
    MED: null,
    CHR: null,
    TEC: null,
    ADM: null,
  },
  /**
   * @typedef {Object} UserOnirosJS
   * @property {Number} id
   * @property {String} username
   * @property {String} firstName
   * @property {String} lastName
   * @property {String} email
   * @property {Boolean} hasTempPassword
   * @property {String} phone
   * @property {Object} region
   * @property {String} region.code
   * @property {String} region.label
   * @property {UserGroupJS[]} groups
   * @property {Boolean} isActive
   * @property {UserOnirosJS} referent
   * @property {Number} alarmTime
   * @property {Number[]} studies - list of the studies ID
   * @property {Number} currentStudyDay
   * @property {Date} inclusionDate
   * @property {Boolean} included
   * @property {Boolean} validCalendar
   * @property {Object} milestonesData
   * @property {Object} StudyData
   * @property {Object} settings
   */
  user: UserEmpty,
  users: [],
  /**
   * @type {UserSettings}
   */
  settings: {
    defaultLanguage: 'fr',
    rememberSelected: false
  }
}

const getters = {
  /**
   * @param {UserState} state
   * @return {UserGroupJS[]}
   */
  groups: state => state.groups,
  /**
   * @param {UserState} state
   * @return {GroupsIdJS}
   */
  groupsId: state => state.groupsId,
  /**
   * Return if user started the luminotherapy
   * @param state
   * @return {*}
   */
  userLuminoStarted: state => state.user.studyData && state.user.studyData.luminotherapy_started.value,
  /**
   * @param {UserState} state
   * @return {UserOnirosJS}
   */
  user: state => state.user,
  /**
   * @param {UserState} state
   * @return {UserListJS}
   */
  users: state => state.users,
}

const actions = {
  /**
   * Create a new user
   *
   * @param {ActionContext} context
   * @param {NewUserFormat} newUser
   * @returns {AxiosPromise<any>}
   */
  createUser: (context, newUser) => {
    if (Number.isInteger(newUser.studies)) {
      newUser.studies = [newUser.studies]
    }

    return _axios.post('users', newUser)
  },
  /**
   * Load a the list of the authentication groups
   *
   * @param {ActionContext} context
   * @returns {Promise<AxiosResponse<any>>}
   */
  loadGroups: ({ commit, state }) => {
    if (state.groups.length === 0) {
      return _axios.get('groups')
        .then(response => {
          commit('LOAD_GROUPS', response.data)
        })
    }
  },
  /**
   * Load the referent user.
   *
   * @param {ActionContext} context
   * @param {UserOnirosJS} user
   * @param {Number} user.id
   * @return {Promise<AxiosResponse<any>>}
   */
  loadReferent: ({ commit }, { id }) => {
    return _axios.get(`users/${id}`)
      .then(response => {
        commit('LOAD_REFERENT', Converter.ptj.user(response.data))
      })
  },
  /**
   * Load one user.
   *
   * @param {ActionContext} context
   * @param {UserOnirosJS} user
   * @param {Number} user.id
   * @return {Promise<AxiosResponse<any>>}
   */
  loadUser: ({ commit, state }, { id }) => {
    if (state.user.id !== id) {
      return _axios.get(`users/${id}`)
        .then(response => {
          commit('LOAD_USER', Converter.ptj.user(response.data))
        })
    }
  },
  /**
   * Load a users list.
   *
   * @param {ActionContext} context
   * @param {UserOnirosJS} user
   * @param {String} user.groupName
   * @param {String} user.referentId
   * @param {String} user.studyId
   * @return {Promise<AxiosResponse<any>>}
   */
  loadUsers: ({ commit }, { groupName = '', referentId = '', studyId = '' }) => {
    let url = 'users'
    let groupFilter = false

    if (groupName !== '') {
      url += `?groups__name=${groupName}`
      groupFilter = true
    }

    if (referentId !== '') {
      url += groupFilter ? '&' : '?'
      url += `referent_id=${referentId}`
      groupFilter = true
    }

    if (studyId !== '') {
      url += groupFilter ? '&' : '?'
      url += `studies=${studyId}`
    }

    return _axios.get(url)
      .then(response => {
        commit('LOAD_USERS', response.data.map(user => Converter.ptj.user(user)))
      })
  },
  /**
   * Update the user.
   *
   * @param {ActionContext} context
   * @param {Object} payload
   * @param {Object} payload.data
   * @param {UserOnirosJS} payload.user
   * @return {null|Promise<AxiosResponse<any>>}
   */
  updateUser: ({ commit }, { data, user }) => {
    return _axios.patch(`users/${user.id}`, Converter.jtp.userToUpdate(data))
      .then(response => {
        commit('LOAD_USER', Converter.ptj.user(response.data))
      })
  },
}

const mutations = {
  /**
   * @param {UserState} state
   * @param {UserGroupJS[]} groups
   * @constructor
   */
  LOAD_GROUPS: (state, groups) => {
    state.groups.splice(0, state.users.length)
    groups.forEach(group => {
      state.groups.push({
        ...group,
        humanName: humanProfile(group.name),
      })

      state.groupsId[group.name] = group.id
    })
  },
  /**
   * @param {UserState} state
   * @param {UserOnirosJS} user
   * @constructor
   */
  LOAD_USER: (state, user) => {
    state.user = Object.assign({}, user)
  },
  /**
   * @param {UserState} state
   * @param {UserOnirosJS} user
   * @constructor
   */
  LOAD_REFERENT: (state, referent) => {
    state.user.referent = Object.assign({}, referent)
  },
  /**
   * @param {UserState} state
   * @param {UserOnirosJS[]} users
   * @constructor
   */
  LOAD_USERS: (state, users) => {
    state.users.splice(0, state.users.length)

    users.forEach(user => {
      state.users.push(Object.assign({}, user))
    })
  },
  /**
   * @param {UserState} state
   * @param {settings[]} settings
   * @constructor
   */
  SET_SETTINGS: (state, settings) => {
    state.settings = settings
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
