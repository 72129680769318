export default {
  ptj: {
    /**
     * @typedef {Object} MilestonePython
     * @property {Number} id
     * @property {String} alert_date
     * @property {string} date
     * @property {string} max_date
     * @property {Number} milestone
     * @property {String} min_date
     * @property {Number} onirosuser
     */
    /**
     * @param {MilestonePython} milestoneParam
     * @return {MilestoneJS}
     */
    milestone: ({ id, alert_date, date, max_date, milestone, min_date, onirosuser }) => {

      return {
        id,
        alertDate: new Date(alert_date),
        date: new Date(date),
        maxDate: new Date(max_date),
        milestoneSettingsId: milestone,
        minDate: new Date(min_date),
        userId: onirosuser,
      }
    },
    /**
     * @typedef {Object} MilestoneSettingsPython
     * @property {Number} day
     * @property {Number} id
     * @property {String} label
     * @property {Number} study
     * @property {Boolean} with_appointment
     */
    /**
     * @param {MilestoneSettingsPython} milestoneParam
     * @return {MilestoneSettingsJS}
     */
    milestoneSettings: ({ study, with_appointment, ...rest }) => {

      return {
        studyId: study,
        withAppointment: with_appointment,
        ...rest,
      }
    },
    /**
     * @typedef {Object} StudyOnirosPython
     * @property {Number} id
     * @property {Number} code - Study code, used to create the PAT username
     * @property {String} label
     * @property {Date} created
     * @property {Date} last_updated
     * @property {Boolean} active
     * @property {String} email
     * @property {Boolean} study_img
     * @property {Boolean} study_pdf
     */
    /**
     * @param {StudyOnirosPython} study
     * @return {StudyOnirosJS}
     */
    study: study => {
      const { last_updated, study_img, study_pdf, created, ...rest } = study

      return {
        ...rest,
        created: new Date(created),
        lastUpdated: new Date(last_updated),
        studyImg: study_img,
        studyPdf: study_pdf,
      }
    },
    /**
     * @typedef {Object} SurveyOnirosPython
     * @property {Number} id
     * @property {String} code
     * @property {String} label
     * @property {Boolean} active
     * @property {Date} created
     * @property {Date} last_updated
     * @property {Boolean} daily
     * @property {Boolean} mandatory
     * @property {Boolean} at_first_login - Answer of this survey in first
     * @property {String} condition
     * @property {Number} Priority
     * @property {Object} questions
     * @property {Object} score_formulas
     * @property {Number} studyId
     * @property {Array|Number[]} milestonesId
     */
    /**
     * @param {SurveyOnirosPython} survey
     * @return {SurveyOnirosJS}
     */
    survey: survey => {
      let { created, last_updated, at_first_login, score_formulas, questions, ...rest } = survey

      // questions.questions = questions.questions.filter((question, index) => index < 10)

      return {
        ...rest,
        created: new Date(created),
        lastUpdated: new Date(last_updated),
        atFirstLogin: at_first_login,
        scoreFormulas: score_formulas,
        questions,
      }
    },
    /**
     * @typedef {Object} SurveyResultsOnirosPython
     * @property {Number} id
     * @property {Boolean} is_completed
     * @property {Boolean} is_draft
     * @property {Boolean} is_empty
     * @property {String} survey_code
     * @property {Number} status
     * @property {Number} day
     * @property {String} date
     * @property {Object} answers
     * @property {Array} scores
     * @property {Number} onirosuser
     * @property {Number} author
     * @property {String} survey
     * @property {Number} milestone
     */
    /**
     * @param {SurveyResultsOnirosPython} surveyResults
     * @return {SurveyResultOnirosJS}
     */
    surveyResults: (surveyResults) => {
      let { date, is_completed, is_draft, is_empty, survey_code, ...rest } = surveyResults

      return {
        ...rest,
        isCompleted: is_completed,
        isDraft: is_draft,
        isEmpty: is_empty,
        surveyCode: survey_code,
        date: new Date(date),
      }
    },
    /**
     * @typedef {Object} UserOnirosPython
     * @property {Number} id
     * @property {String} username
     * @property {String} first_name
     * @property {String} last_name
     * @property {String} email
     * @property {Boolean} has_temp_password
     * @property {String} phone
     * @property {Object} region
     * @property {String} region.code
     * @property {String} region.label
     * @property {Object} groups
     * @property {Number} groups.id
     * @property {String} groups.name
     * @property {Boolean} is_active
     * @property {Number} referent
     * @property {String} alarm_time
     * @property {Number} current_study_day
     * @property {String} inclusion_date
     * @property {Object} study_data
     * @property {Object} milestones_data
     */
    /**
     * @param {UserOnirosPython} user
     * @return {UserOnirosJS}
     */
    user: user => {
      const {
        first_name,
        has_temp_password,
        is_active,
        last_name,
        alarm_time,
        current_study_day,
        inclusion_date,
        study_data,
        milestones_data,
        valid_calendar,
        ...rest
      } = user

      let referent = null
      const referentConverter = function (referentLoaded) {
        const {
          first_name: referent_first_name,
          has_temp_password: referent_has_temp_password,
          is_active: referent_is_active,
          last_name: referent_last_name,
          alarm_time: referent_alarm_time,
          current_study_day: referent_current_study_day,
          inclusion_date: referent_inclusion_date,
          study_data: referent_study_data,
          milestones_data: referent_milestones_data,
          ...rest_referent
        } = referentLoaded

        const referentMilestonesData = {}
        if (Object.keys(referent_milestones_data).length) {
          Object.keys(referent_milestones_data)
                .forEach(milestoneDay => {
                  const { alert_date, date, ...rest } = referent_milestones_data[milestoneDay]
                  referentMilestonesData[milestoneDay] = {
                    alertDate: new Date(alert_date),
                    date: new Date(date),
                    ...rest,
                  }
                })
        }

        return {
          ...rest_referent,
          firstName: referent_first_name,
          lastName: referent_last_name,
          hasTempPassword: referent_has_temp_password,
          isActive: referent_is_active,
          alarmTime: referent_alarm_time ? referent_alarm_time.split(/^([0-9]{2}:[0-9]{2})/)[1] : null,
          currentStudyDay: referent_current_study_day,
          inclusionDate: new Date(referent_inclusion_date),
          studyData: referent_study_data,
          milestonesData: referentMilestonesData,
        }
      }

      if (user.referent) {
        referent = referentConverter(user.referent)

        let referentOfReferent = null
        if (user.referent.referent) {
          referentOfReferent = referentConverter(user.referent.referent)
        }
        referent.referent = referentOfReferent
      }

      const milestonesData = {}
      if (Object.keys(milestones_data).length) {
        Object.keys(milestones_data)
              .forEach(milestoneDay => {
                const { alert_date, date, ...rest } = milestones_data[milestoneDay]
                milestonesData[milestoneDay] = {
                  alertDate: {'value': new Date(alert_date.value), 'description': alert_date.description},
                  date: {'value': new Date(date.value), 'description': date.description},
                  ...rest,
                }
              })
      }

      return {
        ...rest,
        firstName: first_name,
        lastName: last_name,
        hasTempPassword: has_temp_password,
        isActive: is_active,
        alarmTime: alarm_time ? alarm_time.split(/^([0-9]{2}:[0-9]{2})/)[1] : null,
        currentStudyDay: current_study_day,
        inclusionDate: new Date(inclusion_date),
        studyData: study_data,
        validCalendar: valid_calendar,
        milestonesData,
        referent,
      }
    },
    /**
     * @param {UserSurveyOnirosPython} userForm
     * @return {UserSurvey}
     */
    userForm: userForm => {
      const { answer_id, ...rest } = userForm

      return {
        answerId: answer_id,
        ...rest,
      }
    },
    /**
     * @typedef {Object} UserSurveyOnirosPython
     * @property {Number} id
     * @property {String} code - Survey name
     * @property {String} label  Survey label
     * @property {Number} answer_id
     * @property {Number} status - 1 = draft 2 = validated
     */
    /**
     * @typedef {Object} UserDaySurveyOnirosPython
     * @property {Number} day
     * @property {UserSurveyOnirosPython[]} surveys
     */
    /**
     * @param {UserDaySurveyOnirosPython} userSurvey
     */
    userSurvey: userSurvey => {
      userSurvey.surveys = userSurvey.surveys.map(survey => {
        const { answer_id, ...rest } = survey
        return {
          answerId: answer_id,
          ...rest,
        }
      })

      return userSurvey
    },
  },
  jtp: {
    userToUpdate: user => {
      const { firstName, isActive, lastName, alarmTime, ...rest } = user

      let userUpdated = { ...rest }

      if (firstName !== undefined) userUpdated.first_name = firstName
      if (isActive !== undefined) userUpdated.is_active = isActive
      if (lastName !== undefined) userUpdated.last_name = lastName
      if (alarmTime !== undefined && alarmTime !== null) userUpdated.alarm_time = alarmTime + ':00'

      return userUpdated
    },
  },
}
